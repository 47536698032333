/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////// 1430px à 1760px ECRAN INTERMEDIAIRE /////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
@media screen and (min-width: 1430px) and (max-width: 1760px) {

/*body{*/
/*	background-color: red;*/
/*}*/
	.inscri_connect_index_connect	.wrap_btn_index {
		width: 46%;
		margin-left: 4%;
	}

	.reinit_index .lien_inde_btn{
		width: 80%;
		font-size: 17px;
	}

	#sec2{
		margin-top: 67px;
	}

	.col_new a {
		font-size: 1.2em;
	}
	.wrap_h_old {
    top: 20px;
}

	.bienvenue_user {
		color: white;
		font-size: 20px;
	}



	.only_connect {
		margin-bottom: 147px;
		margin-top: 70px;
	}
	.tess{
		height: 210px;
		font-size: 0.9em;
	}

	.cone_inscrip {
		margin-top: 22%;
	}

	#col_name {
		font-size: 1.2em;
	}

	#savoir_plus {
		margin-top: -7%;
	}

	.bienvenue{
	font-size: 	1.2em;
		padding: 6px;
	}

	.item_top a {
		width: 20%;
	}

}

/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////// 1430px à 2000px ECRAN INTERMEDIAIRE /////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*@media screen and (min-width: 1430px) and (max-width: 2000px) {*/

/*	!*.only_connect {*!*/
/*	!*	height: 170px;*!*/
/*	!*	margin-top: 42px;*!*/
/*	!*}*!*/

/*	.bienvenue{*/
/*		padding: 4px;*/
/*		font-size: 1.2em;*/
/*	}*/
/*	.wrap_profil1{*/
/*		top:170px;*/
/*		left: 35%;*/
/*		width: 30%;*/
/*	}*/

/*	#savoir_plus{*/
/*		margin-top: -9%;*/
/*		margin-right: 3%;*/
/*	}*/

/*	!*#col_name{*!*/
/*	!*	font-size: 1.2em;*!*/
/*	!*}*!*/

/*	.tessi_last{*/
/*		height: 38px;*/
/*		font-size: 0.8em;*/
/*	}*/
/*!* #new_iframe { *!*/
/*!*     height: 484px; *!*/
/*!* } *!*/


/*	.col_new a {*/
/*		font-size: 1.1em;*/
/*	}*/
/*	*/
/*	#label_switch {*/
/*		margin-left: 44%;*/
/*	}*/
/*	.modal-content1 {*/
/*		width: 69%;*/
/*		left: 18%;*/
/*	}*/
/*	.dropdown-menu-right {*/
/*		!*   //top: 100%;   *!*/
/*		!*top: 100%;*!*/
/*		!*   //right:-14.5em; *!*/
/*		right: auto;*/
/*		left: 100%;*/
/*		top: 0%;*/
/*	}*/
/*	.logo {*/
/*		width: 9em;*/
/*	}*/
/*	.menubouton {*/
/*		font-size: 13px;*/
/*	}*/
/*	.menubouton li {*/
/*		font-size: 13px;*/
/*	}*/
/*	.menubouton li li {*/
/*		font-size: 13px;*/
/*	}*/
/*	.menu_log li li {*/
/*		font-size: 13px;*/
/*	}*/
/*	.menu_log {*/
/*		font-size: 15px;*/
/*		position: absolute;*/
/*	}*/
/*	a font .badge {*/
/*		font-size: .9em;*/
/*	}*/
/*	.test font {*/
/*		font-size: 13px;*/
/*	}*/
/*	.marge { */
/*		float: left; */
/*		margin-top: 1%; */
/*		margin-left: 16%;*/
/*	}*/
/*	.menu_head {*/
/*		max-height: 70px;*/
/*	}*/
/*}*/

/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////// 1350px à 1430px ECRAN INTERMEDIAIRE /////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
@media screen and (min-width: 1350px) and (max-width: 1430px) {




	.inscri_connect_index	.wrap_btn_index {
		width: 42%;
		margin-left: 6%;
	}

	.reinit_index .lien_inde_btn{
		width: 88%;
		font-size: 17px;
	}

	#sec2{
		margin-top: 67px;
		font-size: 0.9em;
	}

	#sec2 article img {
		width: 70px;
		height: 70px;
	}

	.col_new a {
		font-size: 1.1em;
	}

	.wrap_h_old {
    top: 70px;
}
	.dropdown-menu-right {
		/*   //top: 100%;   */
		/*top: 100%;*/
		/*   //right:-14.5em; */
		right: auto;
		left: 100%;
		top: 0%;
	}
	.logo {
		width: 9em;
	}
	.menubouton {
		font-size: 12px;
	}
	.menubouton li {
		font-size: 12px;
	}
	.menubouton li li {
		font-size: 12px;
	}
	.menu_log li li {
		font-size: 12px;
	}
	.menu_log {
		font-size: 13px;
		position: absolute;
	}
	a font .badge {
		font-size: 0.9em;
	}

	/*Texte cohorte nutrinet*/
	.test font {
		font-size: 12px;
	}
	.marge {
		float: left;
		margin-top: 1%;
		margin-left: 16%;
	}
	.menu_head {
		max-height: 70px;
	}

	.col_new a {
		font-size: 1em;
	}
	.wrap_profil1 {
		top: 168px;
	}


	.tess {
		height: 210px;
		font-size: 0.9em;
	}
	.bienvenue {
		font-size: 1.2em;
	}

	#savoir_plus {
		margin-top: -20px;
	}

	#savoir_plus .hide_two{
		width: max-content;
	}

	.cone_inscrip{
		margin-top: 19%;
	}


}




/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////// 1200px à 1300px ECRAN INTERMEDIAIRE /////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
@media screen and (min-width: 1430px) and (max-width: 1760px) {
.wrap_h_old {
    top: 20px;
}

	.btn-inscription {
		padding: 17px;
	}

}



/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////// 1200px à 1025px ECRAN INTERMEDIAIRE /////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
@media screen and (min-width: 1430px) and (max-width: 1760px) {



	.wrap_h_old {
    top: 40px;
}
	
	
}


/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*//////////// 1025px à 1368px ECRAN INTERMEDIAIRE ALADI /////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
@media screen and (min-width: 1025px) and (max-width: 1368px) {

	.donne_t {
		font-size: 18px;
	}

	.donne_p{
		font-size: 16px;
	}


	#new_iframe {
    height: 352px;
}
	
.flash-noticeInscr {
top: 300px;
}

.flash-notice {
top: 300px;
}

.flash-warning {
top: 300px;
}

.flash-error {
top: 300px;
}



.text-accroche{
	font-size: 24px;
}	
.wrap_face1 .tac_activate {
font-size: 11px;
}
	
#col_name {
margin-bottom: 30px;
}
	
	
.user_petit div {
margin-top: 2px;
}
	
	#wrap_im {
    margin-top: 8%;
    margin-bottom: 10px;
}
	
	
	#wrap_im .last_wrap_dip {
	top: 195px;
}
	
	.face2facebook {
    width: 52%;
    float: left;
    margin-left: 16%;
}
	
	.cache_mise {
		width: 80%;
	}
	.wrap_dip {
		margin-left: -11%;
	}
	.wrap_hide {
		width: 40%;
	}
	.little_level {
		width: 20%;
	}
	.user_petit button {
		width: 73%;



	}
	.wrap_profil1 {
		font-size: 0.8em;
		height: 31px;
		left: 29%;
		width: 35%;
		top: 164px;
		
	}
	.tess {
		height: 179px;
	}
	.tessi_last {
		height: 30px;
	}
	.wrap_accroche {
		left: 25%;
		width: 50%;
		font-size: 1.6em;
		top: 253px;
		/* 	z-index: 2000; */
	}
	#actualites {

		margin-top: 14%;
	}
	.part img {
		width: 73%;
	}
	#ftr article {
		margin-left: 10%;
		width: 20%;
		font-size: 0.9em;
	}
	#ftr article h2 {
		font-size: 1.5em;
	}
	#ftr article a {
		line-height: 26px;
	}
	#btval {
		width: 11%;
		font-size: 15px;
		padding: 1px;
	}
	#btl {
		width: 20%;
		font-size: 14px;
		padding: 4px;
	}
	.wrap_content {
		font-size: 0.9em;
		/*margin-top: 5%;*/
		margin-right: 2%;
	}

	/*A METTRE DANS LE PROD*/
	.menubouton {
		margin-left: 0.5%;
		width: 70%;
	}
	.menubouton li {
		font-size: 0.9em;
	}



	.col_new {
		font-size: 80%;
		width: 33%;
		margin-left: 0%;
	}
	.btn-inscription {
		padding: 17px;
	}
	/*#sec2 {*/
	/*	margin-top: -2%;*/
	/*	font-size: 85%;*/
	/*	height: 270px;*/
	/*}*/

	/*#sec2 img {*/
	/*	width: 65px;*/
	/*	height: 65px;*/
	/*}*/
	.number_user {
		height: 31%;
		line-height: 6.9em;
		font-size: 0.8em;
	}
	#sec2 article {
		height: 81%;
	}
	.crumb li {
		padding: 1px 10px 1px 10px;
	}

	/* .bienvenue_user { */

	/*     font-size: 100%; */

	/* } */
	.navbar-nav>li>a {
		line-height: 25px;
		height: 167px;
	}
	.logo {
		width: 7.5em;
		margin-top: -9%;
	}
	.alertadmin2 {
		width: 89%;
		left: 5%;
		top: 53%;
	}
	.bienvenue_user {
		color: white;
		font-size: 20px;
	}

	.btn-inscription {
		padding: 10px;

	}
	.col_new a {
		font-size: 0.9em;
		line-height: 35px;
	}

	#sec2 article blockquote {
		padding: 25px 15px;
		margin: 0 0 20px;
		/*font-size: 1em;*/
		border-left: none;
	}

	.bienvenue {
		padding: 4px;
		font-size: 1.1em;
	}

	.partenaire img {
		margin-bottom: 5%;
		width: 50%;
	}

	.cone_inscrip {
		margin-top: 17%;
		padding: 40px;
	}

}

/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////// 1270px à 1350px ECRAN INTERMEDIAIRE /////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
@media screen and (min-width: 1270px) and (max-width: 1350px) {
		.wrap_h_old{
	top: 70px;
	}


	#sec2 {
		margin-top: 78px;
	}

	.nutrinet_btn_index {
		width: 74%;
		margin-top: 70px;
	}
	
	.dropdown-menu-right {
		/*   //top: 100%;   */
		/*top: 100%;*/
		/*   //right:-14.5em; */
		right: auto;
		left: 100%;
		top: 0%;
	}
	.btn-inscription, .btn-number {
		font-size: 12px;
	}
	.logo {
		width: 7.5em;
		margin-top: -3%;
	}
	.menubouton {
		font-size: 12px;
	}
	.menubouton li {
		font-size: 12px;
	}
	.menubouton li li {
		font-size: 12px;
	}
	.menu_log li li {
		font-size: 12px;
	}
	.menu_log {
		font-size: 13px;
		position: absolute;
	}
	a font .badge {
		font-size: 0.9em;
	}

	/*Texte cohorte nutrinet*/
	.test font {
		font-size: 12px;
	}
	.marge {
		float: left;
		margin-top: 1%;
		margin-left: 16%;
	}
	.menu_head {
		max-height: 70px;
	}
}

/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////// 1182px à 1302px ECRAN INTERMEDIAIRE /////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
@media screen and (min-width : 1182px) and (max-width : 1302px) {
	.wrap_h_old{
	top: 68px;
	
}

	.login .etude {
		font-size: 0.9em;
	}
	.login .slogan {
		font-size: 0.8em;
	}
}

/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////// 1240px à 1270px ECRAN INTERMEDIAIRE /////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
@media screen and (min-width: 1240px) and (max-width: 1270px) {

	#sec2 {
		margin-top: 78px;
	}

	.nutrinet_btn_index {
		width: 74%;
		margin-top: 70px;
	}


	.dropdown-menu-right {
		/*   //top: 100%;   */
		/*top: 100%;*/
		/*   //right:-14.5em; */
		right: auto;
		left: 100%;
		top: 0%;
	}
	.btn-inscription, .btn-number {
		font-size: 12px;
	}
	.logo {
		width: 8em;
	}
	.menubouton {
		font-size: .9em;
		margin-left: 1%;
	}
	.menubouton li {
		font-size: .9em;
	}
	.menubouton li li {
		font-size: .9em;
	}
	.menu_log li li {
		font-size: .9em;
	}
	.menu_log {
		font-size: .95em;
		position: absolute;
	}
	a font .badge {
		font-size: 0.9em;
	}

	/*Texte cohorte nutrinet*/
	.test font {
		font-size: 0.9em;
	}
	.marge {
		float: left;
		margin-top: 1%;
		margin-left: -1.5%;
	}
	.menu_head {
		max-height: 70px;
	}
}

/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
/*////// ECRAN ENTRE  1259px ET 840px ///////*/
/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
@media screen and (max-width: 1259px) and (min-width: 840px) {
	
	.wrap_h_old{
	top: 70px;
	}
	
	.nutrinautes {
		font-size: 1.4em;
	}
	.tuileTexte {
		font-size: 0.9em;
	}

	.btn-inscription {
		padding: 14px;
		font-size: 0.9em;
	}
}

/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
/*////// ECRAN ENTRE  1259px ET 768px ///////*/
/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
@media screen and (max-width: 1259px) and (min-width: 768px) {

	.btn-inscription {
		padding: 13px;
		font-size: 0.9em;
	}
	
	.wrap_h_old{
	top: 100px;
	}
	
	.thumbnail.partenaire {
		height: 120px;
	}
	.caption.partenaire {
		font-size: 0.7em;
	}
}

/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////// 1180px à 1240px ECRAN INTERMEDIAIRE /////////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
@media screen and (min-width: 1180px) and (max-width: 1240px) {

	#sec2 {
		margin-top: 53px;
	}

	.nutrinet_btn_index {
		width: 74%;
		margin-top: 70px;
	}
	.wrap_h_old{
	top: 70px;
	}
	.dropdown-menu-right {
		/*   //top: 100%;   */
		/*top: 100%;*/
		/*   //right:-14.5em; */
		right: auto;
		left: 100%;
		top: 0%;
	}
	.btn-inscription, .btn-number {
		font-size: 12px;
	}
	.logo {
		width: 8em;
	}
	.menubouton {
		font-size: .87em;
		margin-left: 1%;
	}
	.menubouton li {
		font-size: .87em;
	}
	.menubouton li li {
		font-size: .87em;
	}
	.menu_log li li {
		font-size: .87em;
	}
	.menu_log {
		font-size: .87em;
		position: absolute;
	}
	a font .badge {
		font-size: 0.9em;
	}

	/*Texte cohorte nutrinet*/
	.test font {
		font-size: 0.87em;
	}
	.marge {
		float: left;
		margin-top: 1%;
		margin-left: -1.5%;
	}
	.menu_head {
		max-height: 70px;
	}
}

/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
/*////// ECRAN ENTRE  1080px ET 1180px /////*/
/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
@media screen and (min-width: 1080px) and (max-width: 1180px) {

	#sec2 {
		margin-top: 53px;
		height: 364px;
	}

	.nutrinet_btn_index {
		width: 85%;
		margin-top: 32px;
	}

	.wrap_h_old{
	top: 68px;
	
}
	.dropdown-menu-right {
		/*   //top: 100%;   */
		/*top: 100%;*/
		/*   //right:-14.5em; */
		right: auto;
		left: 100%;
		top: 0%;
	}

	.logo {
		width: 8em;
	}
	.menubouton {
		font-size: .87em;
		margin-left: 1%;
	}
	.menubouton li {
		font-size: .87em;
	}
	.menubouton li li {
		font-size: .87em;
	}
	.menu_log li li {
		font-size: .87em;
	}
	.menu_log {
		font-size: .87em;
		position: absolute;
	}
	a font .badge {
		font-size: 0.9em;
	}

	/*Texte cohorte nutrinet*/
	.test font {
		font-size: 0.87em;
	}
	.marge {
		float: left;
		margin-top: 1%;
		margin-left: -1.5%;
	}
	.menu_head {
		max-height: 70px;
	}
	.hidden-sm {
		display: none !important;
		visibility: hidden;
		width: 0px;
	}
	#bar {
		visibility: hidden;
	}
}

/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
/*////// ECRAN ENTRE  1040px ET 1080px /////*/
/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
@media screen and (min-width: 1040px) and (max-width: 1080px) {

	#sec2 {
		margin-top: 53px;
		height: 364px;
	}

	.nutrinet_btn_index {
		width: 85%;
		margin-top: 32px;
	}


	.dropdown-menu-right {
		/*   //top: 100%;   */
		/*top: 100%;*/
		/*   //right:-14.5em; */
		right: auto;
		left: 100%;
		top: 0%;
	}
	.btn-inscription, .btn-number {
		font-size: 10px;
	}
	.logo {
		width: 6.5em;
	}
	.menubouton {
		font-size: .85em;
		margin-left: 1%;
	}
	.menubouton li {
		font-size: .85em;
	}
	.menubouton li li {
		font-size: .85em;
	}
	.menu_log li li {
		font-size: .85em;
	}
	.menu_log {
		font-size: .85em;
		position: absolute;
	}
	a font .badge {
		font-size: 0.9em;
	}

	/*Texte cohorte nutrinet*/
	.test font {
		font-size: 0.85em;
	}
	.marge { 
		float: left; 
		margin-top: 1%;
		margin-left: -1.5%;
	}
	.menu_head {
		max-height: 70px;
	}
	.hidden-sm {
		display: none !important;
		visibility: hidden;
		width: 0px;
	}
	#bar {
		visibility: hidden;
	}
}

/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
/*////// ECRAN ENTRE  840px ET 1040px ///////*/
/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
@media screen and (min-width: 840px) and (max-width: 1040px) {

	#sec2 {
		margin-top: 53px;
		height: 364px;
	}

	.nutrinet_btn_index {
		width: 85%;

		margin-top: 32px;
	}

	.dropdown-menu-right {
		/*   //top: 100%;   */
		/*top: 100%;*/
		/*   //right:-14.5em; */
		right: auto;
		left: 100%;
		top: 0%;
	}
	.btn-inscription, .btn-number {
		font-size: 8px;
	}
	.logo {
		width: 6.5em;
	}
	.menubouton {
		font-size: .85em;
		margin-left: 1%;
	}
	.menubouton li {
		font-size: .85em;
	}
	.menubouton li li {
		font-size: .85em;
	}
	.menu_log li li {
		font-size: .85em;
	}
	.menu_log {
		font-size: .85em;
		position: absolute;
	}
	a font .badge {
		font-size: 0.9em;
	}

	/*Texte cohorte nutrinet*/
	.test font {
		font-size: 0.85em;
	}
	.marge {
		float: left;
		margin-top: 1%;
		visibility: hidden;
		width: 0px;
		height: 0px;
	}
	.menu_head {
		max-height: 70px;
	}
	.hidden-sm {
		display: none !important;
		visibility: hidden;
		width: 0px;
	}
	#bar {
		visibility: hidden;
	}
}



