
/*(((((((((((((((((((((((((((((((((((((((((((((((((((( OPEN SANS ))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))*/
/*@font-face */
/*{*/
/*    font-family: "Open Sans";*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    src: url('../polices/opensans-regular-webfont.woff') format('woff'),*/
/*         url('../polices/opensans-regular-webfont.woff2') format('woff2'), */
/*         url('../polices/OpenSans-Regular.ttf')  format('truetype'),*/
/*   */
/*}*/


/*@font-face */
/*{*/
/*    font-family: "Open Sans";*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    src: url('../polices/opensans-semibold.woff') format('woff'),*/
/*         url('../polices/opensans-regular-webfont.woff2') format('woff2'), */
/*         url('../polices/OpenSans-Semibold.ttf')  format('truetype'),*/
/*   */
/*}*/






/*(((((((((((((((((((((((((((((((((((((((((((((((((((( DORIS ))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))*/
/*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@font-face 
{
    font-family: "Dosis";
    font-style: normal;
    font-weight: 300;
    src: url('../polices/Dosis-Light.ttf') format('truetype'),
   
}





@font-face 
{
    font-family: "Dosis";
    font-style: normal;
    font-weight: 400;
    src: url('../polices/Dosis-Regular.ttf') format('truetype'),
   
}







/*(((((((((((((((((((((((((((((((((((((((((((((((((((( ROBOTO ))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))*/
/*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

@font-face 
{
    font-family: "Dosis";
    font-style: normal;
    font-weight: 400;
    src: url('../polices/Dosis-Regular.ttf') format('truetype'),
   
}


@font-face {
    font-family: 'robotobold';
    src: url('../polices/roboto-bold-webfont.woff2') format('woff2'),
         url('../polices/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'robotolight';
    src: url('../polices/roboto-light-webfont.woff2') format('woff2'),
         url('../polices/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'robotomedium';
    src: url('../polices/roboto-medium-webfont.woff2') format('woff2'),
         url('../polices/roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'robotoregular';
    src: url('../polices/roboto-regular-webfont.woff2') format('woff2'),
         url('../polices/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

