*{
    margin:0;
    padding:0;
}

/*.container{  padding-top: 20px;*/
/*    padding-bottom: 20px;*/
/*}*/


.box{
    position: relative;
    margin: 0 auto;
    width: 700px;
    height: auto;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 5px 14px rgb(23, 32, 90);
    color: white;
    padding: 20px;
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 0;
    margin-top: -95px;
}




.box span:nth-child(odd){
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
}


.box span:nth-child(even){
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
}


.box span:nth-child(1)
{
    transform:rotate(0deg);
    right: 20px !important;
}

.box span:nth-child(2)
{
    transform:rotate(90deg);
    left: 270px !important;
}

.box span:nth-child(3)
{
    transform:rotate(180deg);
    right: -20px !important;
}

.box span:nth-child(4)
{
    transform:rotate(270deg);
    left: -270px !important;
}

/*largeur*/
.box span:nth-child(odd):before
{
    content: '';
    position: absolute;
    left: 0;
    width:15%;
    height: 4px;
    background: #e2007a;
    animation: slidein 3s linear 1;
    animation-direction: normal;


}


.box span:nth-child(even):before
{
    content: '';
    position: absolute;
    width:50%;
    height: 4px;
    background: #e2007a;
    animation: slidein 4s linear 1;
    animation-direction: normal;
    right:0;
}




@keyframes slidein {
    0% {
        transform:scaleX(0);
        transform-origin: right;
    }
    50%
    {
        transform:scaleX(1);
        transform-origin: right;
    }


}

/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
/*//////////// 1025px à 1368px ECRAN INTERMEDIAIRE ALADI /////*/
/*////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////*/
@media screen and (min-width: 1025px) and (max-width: 1368px) {

    .box{
        width: 652px;
        box-shadow: 0 2px 9px rgb(23, 32, 90);
        color: white;
        padding: 10px;
        font-size: 1.9em;

    }


}




/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*///////////////TABLETTE PORTRAIT/////////*/
/*/////////////////////////////////////////*/
/*////////////////LES ACTIVITES////////////*/




/*RESPONSIVE TABLETTE */
@media screen and (min-width: 768px) and (max-width: 1024px) {

    .box{
        width: 480px;
        padding: 12px;
        font-size: 1.5em;
        margin-top: -90px;
    }



    .box span:nth-child(2)
    {
        transform:rotate(90deg);
        left: 192px !important;
    }



    .box span:nth-child(4)
    {
        transform:rotate(270deg);
        left: -192px !important;
    }




}


/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*////////////// TABLETTE PAYSAGE ////////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/

@media screen and (min-width:768px) and (max-width:1024px) and (orientation:landscape) {

    .box{
        width: 480px;
        padding: 12px;
        font-size: 1.5em;
        margin-top: -90px;
    }

    .box span:nth-child(2)
    {
        transform:rotate(90deg);
        left: 192px !important;
    }



    .box span:nth-child(4)
    {
        transform:rotate(270deg);
        left: -192px !important;
    }





}



/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*///////// SMARTPHONE PORTRAIT //////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/




@media screen and (max-width: 767px) {

    .box {
        width: 253px;
        box-shadow: 0 3px 7px rgb(23, 32, 90);
        padding: 20px;
        font-size: 1.2em;
        text-align: center;
        margin-bottom: 120px;
        margin-top: 0;
        font-weight: bold;
        background-color: #00000094;

    }

    .box span:nth-child(2)
    {
        transform:rotate(90deg);
        left: 63px !important;
    }

    .box span:nth-child(4)
    {
        transform:rotate(270deg);
        left: -63px !important;
    }

    .box span:nth-child(odd):before
    {
        width:30%;
        animation: slidein 2s linear 1;
    }

    .box span:nth-child(even):before
    {
        width:50%;
    }



}