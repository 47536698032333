* {
	outline: 0;
}
body {
	font-family: Dosis, sans-serif !important;
}

.label_text_riche > p {
	display: contents;
}

#wrap_img_header_smartphone{
	display: none;
}


.wrap_btn_index{
	width: 38%;
	margin-left: 8%;
	float: left;
}

.highlight-vert-fonce {
	background-color: #66cc00;
	border-radius: 5px;
	padding: 3px;
}



.nutrinet_btn_index{
	width: 50%;
	margin: 0 auto;
	margin-top: 70px;
}

.reinit_index_connect .wrap_btn_index_reinit{
	width: 46%;
	margin: 0 auto 0 32%;

}


.reinit_index .wrap_btn_index_reinit{
	width: 69%;
	margin: 0 auto 0 21%;

}

.inscri_connect_index{
	font-size: 22px;
	margin: 0 auto;
	width: inherit;
	text-align: center;
	margin-bottom: 30px;
	float: left;
	width: 100%;
}

.inscri_connect_index_connect{
	font-size: 22px;
	margin: 0 auto;
	width: inherit;
	text-align: center;
	margin-bottom: 30px;
	float: left;
	width: 100%;
}


.inscri_connect_index_connect .wrap_btn_index .lien_inde_btn{
	padding: 1%;
	background-color: #e2007a;
	color: white;
	border: 2px solid #e2007a;
	font-weight: bold;
	display: block;
	width: 85%;
	position: relative;
	float: left;
	height: 45px;
	border-right: none;
	line-height: 34px;
}


.inscri_connect_index .wrap_btn_index .lien_inde_btn{
padding: 1%;
background-color: #e2007a;
color: white;
border: 2px solid #e2007a;
font-weight: bold;
display: block;
width: 85%;
position: relative;
float: left;
height: 45px;
border-right: none;
line-height: 34px;
}

.inscri_connect_index .wrap_btn_index  i{
	padding: 1%;
	color: #e2007a;
	display: block;
	height: max-content;
	width: 15%;
	float: left;
	border: 2px solid #e2007a;
	border-left: none;
	margin: 0;
	height: 45px;
	line-height: 34px !important;
	font-size: 30px;
	font-weight: bold !important;
}

.inscri_connect_index_connect .wrap_btn_index  i{
	padding: 1%;
	color: #e2007a;
	display: block;
	height: max-content;
	width: 15%;
	float: left;
	border: 2px solid #e2007a;
	border-left: none;
	margin: 0;
	height: 45px;
	line-height: 34px !important;
	font-size: 30px;
	font-weight: bold !important;
}

.inscri_connect_index .wrap_btn_index .lien_inde_btn:hover{
	cursor: pointer;
	color: #e2007a;
	background-color: white;
}

.inscri_connect_index_connect .wrap_btn_index .lien_inde_btn:hover{
	cursor: pointer;
	color: #e2007a;
	background-color: white;
}


.reinit_index{
	margin: 0 auto;
	text-align: center;
	clear: both;
	width: 100%;

}

.reinit_index_connect{
	margin: 0 auto;
	text-align: center;
	clear: both;
	width: 100%;

}


.reinit_index .lien_inde_btn, .reinit_index_connect .lien_inde_btn{
	padding: 1%;
	background-color: #75b70b;
	color: white;
	border: 2px solid #75b70b;
	font-weight: bold;
	font-size: 19px;
	position: relative;
	width: 73%;
	display: block;
	margin: 0 auto;
	float: left;
	border-right: none;
	height: 45px;
	line-height: 34px;
}








.reinit_index .lien_inde_btn:hover{
	cursor: pointer;
	color: #75b70b;
	background-color: white;
}

.reinit_index_connect .lien_inde_btn:hover{
	cursor: pointer;
	color: #75b70b;
	background-color: white;
}

.reinit_index_connect .wrap_btn_index_reinit i{
	font-weight: bold !important;
	padding: 1%;
	color: #75b70b;
	display: block;
	height: max-content;
	width: 10%;
	float: left;
	border: 2px solid #75b70b;
	border-left: none;
	margin: 0;
	font-size: 30px;
	height: 45px;
	line-height: 34px;
}
.reinit_index .wrap_btn_index_reinit i{
	font-weight: bold !important;
	padding: 1%;
	color: #75b70b;
	display: block;
	height: max-content;
	width: 8%;
	float: left;
	border: 2px solid #75b70b;
	border-left: none;
	margin: 0;
	font-size: 30px;
	height: 45px;
	line-height: 34px;
}

/*.reinit_index .wrap_btn_index_reinit a:last-child{*/
/*	font-weight: bold !important;*/
/*	padding: 1%;*/
/*	color: #75b70b;*/
/*	display: block;*/
/*	height: max-content;*/
/*	width: 10%;*/
/*	float: left;*/
/*	border: 2px solid #75b70b;*/
/*	border-left: none;*/
/*	margin: 0;*/
/*	font-size: 30px;*/
/*	height: 45px;*/
/*	line-height: 34px;*/
/*}*/



#acrroche_text_header{
	position: absolute;
	top: 212px;
	/*background-color: yellow;*/
	width: 40%;
	left: 30%;
	text-align: center;
	font-size: 2.5em;
	padding: 1%;
	border: 2px solid #e2007a;
	color: white;
	font-weight: normal;
}

.accordion-flush{
	margin-top: 20px;
}

/*input[type="submit"]{*/
/*	color: white;*/
/*}*/

#creer2{
	color: #404040 !important;
}

.accordion-body{
	padding: 1% !important;
	border: 1px solid rgba(0,0,0,.125) !important;
	border-top: none;
	border-bottom: none;
}

.checkline > .radio-inline{
	margin-right: 1%;
}

.alert-danger{
	font-weight: bold !important;
	padding: 5px !important;
}


.checkbox > label{
	font-weight: normal !important;
}
#gotomessageSupport{
	color: white !important;
	font-weight: bold !important;
}


.control-label{
	font-weight: bold !important;
}

#form_save{
	font-weight: 700;
	border: 1px solid #fff;
	background-color: #e2007a;
	color: #fff;
	border-radius: 0;
	margin-top: 1%;
}

#form_save:hover{
	background-color: #fff;
	color: #e2007a;
	border: 1px solid;
}

.btn-index-campagne-admin{
	background-color: transparent;
	border: none;
	padding: 0;
}

.accordeon-relative{
	position: relative;
}

.title_bilan_q{
	width: 83%;
}
.wrap_bas_article{
	margin-top: 3%;
	border: 1px solid #f2f2f2;
	padding-bottom: 3%;
	padding-top: 1%;
}
.pull-right{
	margin-left: 1%;
	float: right !important;
}



#wrap_msg_error{
	text-align: center;
	/*font-family: arial;*/
}

#wrap_msg_error h1{
	margin-top: 5%;
	font-size: 10em;
	color: #e2007a;
}

#wrap_msg_error h2{
	font-size: 2em;
}

#new_iframe{
	width: 100%;
	height:479px;
}


#new_iframe iframe{
	width: 100%;
	height:100%;
}

.target_faq{
	padding-left: 10px;
	padding-top: 10px;
	padding-bottom: 25px;
}



.retour_qa{
	color: white;
	background-color: #e2007a;
	padding: 10px;
	text-align: center;
	font-weight: bold;
	margin-left: 1%;
	float: left;
	margin-top: 1%;
}

.retour_qa:hover{
	background-color: white;
	color: #e2007a;
	border: 1px solid;
}
/* .cache_btn_share{ */
/* width: 84%; */
/* position: absolute; */
/* left: 103px; */
/* /* background: red; */ 
/* z-index: 2; */
/* top: -10px; */
/* padding: 18px; */
/* } */

/* .right_marge_edit{ */
	
/* } */
.right_marget{
	margin-right: 6%;
}

.faq_quest{
float: left;
width: 74%;
}

.entete_left_faq{
float: left;
width: 74%;
}	
.entete_faq{
	background:#e2007a; 
	color:white;font-weight:bold;
	margin-left: 2%;
	padding-top: 10px;
	padding-bottom: 10px;
	height: 39px;
	padding-left: 10px;
	width: 98%;
	
}	
	
	
.right_t{
float: left;
width: 5%;
}


.right_a{
	float: right;
}

.right_b{
	float: right;
}		
.right_marge{
margin-right: 7%;
width: 5%;
float: left;
}	

	
	.wrap_form_render input[type="number"]{
	width: 125px;
}	
	
	.wrap_form_render input[type="date"]{
	width: auto;
}	


.wrap_face{
	margin-top: -8px;
}



.face2facebook span{
	width: 0px;
}

.in-like{
	margin-right: 3px;
}
/*Question Consentement */
.label_render.label_nofontweight {
	font-weight: initial;
}
.bouton_bleu {
	display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
     border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 4px;
    position: relative;
    bottom: 40px;
    float: right !important;
    /*font-family: inherit;*/
    text-transform: none;
    overflow: visible;
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
    position: inherit;
    margin-top: 10px;
    margin-bottom: 10px;
}
/*Pour ne pas afficher les fleches dans champ type number */

/*.article .body p img{
	width: 
}

 */

.fait_le_niveau{
	padding-left: 66%;
/* 	background-color: red; */
}


.next_profil{
	float: right;
}

.paristraize{
	width: 76%;
}

input[type=number] {
    -moz-appearance: textfield;
}
/* Chrome */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button { 
	-webkit-appearance: none;
	margin:0;
}
/* Opéra*/
/*input::-o-inner-spin-button,*/
/*input::-o-outer-spin-button { */
/*	-o-appearance: none;*/
/*	margin:0*/
/*}*/


.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 351px;
}

.inscri_be{
	font-size: 50px;
}	


.title-news_be{
	background: #e2007a;
	padding: 10px;
	margin: 0;
	margin-bottom: 2%;
	color: white;
font-weight: bold;
}

/*#liste_add_btn{*/
/*background-color: #dbdbdb66;*/
/*padding: 5px;*/
/*cursor: pointer;*/
/*border: 1px solid #bab0b0;*/
/*border-radius: 3px;*/
/*}*/

.edit_art_one{
	width: 20%;
	margin-top: 2%;
	font-size: 1.3em;
	font-weight: bold;
	color: black;

}

.edit_art_one .bi{
color: #e2007a;
}

.spece{
	height: 15em;
}
.text-accroche{
	font-size: 28px;
}

.title_nutrinet{
	margin-bottom: 70px;
	margin-left: 3%;
	border-bottom: 1px solid #e2007a;
	border-top: 1px solid #e2007a;
	width: -moz-max-content;
	width: max-content;
	padding: 7px;
	
}


#no_news{
font-size:25px;
color:#e2007a;
display:none;text-align: center;
margin-top: -91px;
}


#dynamic{
	margin:auto;
	display:block;
	margin-top: -93px;
}


.title_news1{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e2007a+0,75b70b+100 */
background: #e2007a; /* Old browsers */
background: -moz-linear-gradient(left,  #e2007a 0%, #75b70b 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  #e2007a 0%,#75b70b 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  #e2007a 0%,#75b70b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2007a', endColorstr='#75b70b',GradientType=1 ); /* IE6-9 */
padding: 10px 15px;
border-bottom: 1px solid transparent;
border-bottom-color: transparent;
border-top-left-radius: 3px;
border-top-right-radius: 3px;
font-size: 20px;
color:white;
margin-bottom: 5px;
font-weight: bold;
}

.article{
margin-bottom: 120px;
display: inline-block;
padding-bottom: 20px;
padding-left: 10px;
padding-right: 10px;
border-bottom: 1px solid #e2007a; /* Old browsers */
border-left: 1px solid #e2007a; /* Old browsers */
border-right: 1px solid #e2007a; /* Old browsers */
width: 100%;

}

.liste_delete_btn{
	color: red;
}


.cons_view_news{
float: right;
margin-right: 1%;
margin-bottom: 20px;
margin-top: 20px;
}


/*.cons_view_news a{*/
/*	color:white;*/
/*	background-color: #e2007a;*/
/*	width: 10%;*/
/*	padding: 5px;*/
/*	text-align: center;*/
/*	font-size: 18px;*/
/*	font-weight: bold;*/
/*}*/
	
	
/*.cons_view_news a:hover{*/
/*	opacity: 0.5;*/
/*}*/

.result_wrap  .tac_activate {
background: #333;
width: 0%;
}

.result_wrap .tac_float{
width:20%;
}


/* Texte défilant */
.face2facebook {
width: 0;
display: block;
margin-left: 61%;
}

.face2twitter {
width: auto;
display: block;
}

#twitter_unique{
	margin-right: 4%;
}

.wrap_linkedin{
margin-top: -10px;
}
.wrap_linkedin2{
margin-right: 1%;
}
/***** CODE POUR LE MESSAGE DE MAINTENANCE *******/
.messagedefilant {
	display: block;
	margin: 231px auto;
	padding: 0;
	overflow: hidden;
	position: absolute;
	width: 50%;
	width: 98%;
	height: 40px;
	z-index: 2000;
	left: 1%;
	background-color: green;
	color: white;
	font-weight: bold;
}

.messagedefilant div {
	position: absolute;
	width: 100%; /* au minimum la largeur du conteneur */
	line-height: 3em;
}

.messagedefilant div span, .messagedefilant div:after {
	position: relative;
	display: inline-block;
	font-size: 2rem;
	white-space: nowrap;
	top: 0;
}

.messagedefilant div span {
	animation: defilement 20s infinite linear;
	/*   background: #cde; */
}

.messagedefilant div:after {
	position: absolute;
	top: 0;
	left: 0;
	content: attr(data-text);
	animation: defilement2 20s infinite linear;
	/*   background: #edc; */
}

@keyframes defilement{ 
0% {margin-left: 0;}
100%{ margin-left:-100%;}

}

@keyframes defilement2 { 
0%{margin-left: 100%;}
100%{margin-left:0%;}

}

/***** FIN CODE POUR LE MESSAGE DE MAINTENANCE *******/
#texte_label {
	float: left;
	line-height: 3em;
	font-size: 17px;
	margin-left: 2%;
	font-weight: bold;
}

#switch[type=checkbox] {
	height: 0;
	width: 0;
	visibility: hidden;
	float: left;
}

#label_switch {
	cursor: pointer;
	text-indent: -9999px;
	width: 90px;
	height: 39px;
	background: grey;
	display: block;
	border-radius: 39px;
	position: relative;
	box-shadow: 2px 2px 3px black;
	margin-left: 31%;
}

#label_switch:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 30px;
	height: 30px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

#switch:checked+#label_switch {
	background: #e2007a;
}

#switch:checked ~ .wrap_profil .tr_profil .valeur_profil {
	position: relative;
	visibility: hidden;
}

#switch:checked ~ .wrap_profil .tr_profil .valeur_profil:after {
	content: '*************************';
	position: absolute;
	top: 5px;
	left: 30px;
	visibility: visible;
	font-size: 20px;
}

#switch:checked+#label_switch:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

#label_switch:active:after {
	width: 130px;
}

/* body { */
/* 	display: block; */
/* 	justify-content: center; */
/* 	align-items: center; */
/* 	height: 100vh; */
/* } */




.legend_profil {
	font-size: 18px;
	font-weight: bold;
	margin-top: 4%;
}

.wrap_profil {
	display: block;
	width: 96%;;
	margin: 0 auto;
	margin-top: 2%;

}

.tr_profil {
	width: 100%;
	display: block;
	border: 1px solid #e2007a;
	margin: 0;
	margin-top: 2%;
	height: 30px;
}

.title_profil {
	float: left;
	width: 34%;
	background-color: #e2e6dc;
	padding: 5px;
	padding-left: 10px;
	text-align: left;
	color: #e2007a;
	font-weight: bold;
	border-right: 1px solid #e2007a;
	height: 28px;
}

.valeur_profil {
	width: 61%;
	padding: 5px;
	padding-left: 3%;
	margin-left: 33%;
	height: 28px;
}

.grey_adresse {
	background-color: #ada5a5cc;
}





.modal-footer .btn-primary {
	background-color: #e2007a;
	border-color: #e2007a;
	font-weight: bold;
}

.modal-footer .btn-primary:hover {
	background-color: white;
	border-color: #e2007a;
	font-weight: bold;
	color: #e2007a;
}

.modal-body1 fieldset {
	border: #e2007a 1px solid;
	margin-top: 2%;
}

.modal-body1  legend {
	color: #e2007a;
}

.modal-content1 {
	margin: 0 auto;
	width: 50%;

}

.modal-title1 {
color: #e2007a;
font-size: 1.5em;
font-weight: bold;
}


/* Pour les label des questionnaires */
.label_render {
	margin-top: 15px;
	margin-bottom: 5px;
	font-weight: bold;
}

.label_render.sub_label {
	margin-top: 0;
	margin-bottom: 5px;
}

.texte_seul {
	margin-top: 20px;
	margin-bottom: 5px;
}

#renderQuest .checkline .checkbox, #renderQuest .checkline .radio{
	margin-top : 0px;
}

#renderQuest .form-group{
	margin-bottom : 0px;
}

#renderQuest #renderQuestTab .checkline .checkbox{
	margin-top : 10px;
}
/**/

/* Le css pour les données du  niveau et profil */
/*CSS POUR LE DIPLOME ALADI*/
#savoir_plus .hide_two {
	display: none;
	position: absolute;
	top: -25px;
	right: 0;
}


.wrap_face {
	margin-top: 0px;
}

.wrap_face1 {
	position: relative;
	margin-top: 0px;
	height: auto;
	margin-left: 34%;
	width: 105px;
	
	}

#wrap_im .last_wrap_dip {
	position: absolute;
	display: none;
	left: -85%;
	top: 224px;
	left: -74%;
	/* 	 width: 1123px; */
	/*        height: 794px; */
}

/*  #wrap_im:hover .last_wrap_dip{ */
/* 	display:block; */
/* 	z-index: 2000; */
/*transition: 5.3s; */
/* 	} */
.wrap_dip {
	padding-top: 20px;
	position: relative;
	width: 1123px; /*100%*/
	height: 794px; /*auto*/
	margin: 0 auto;
	/*font-family: 'Roboto', sans-serif;*/
	background-position: center center;
	text-align: center;
	color: #3f404b;
	font-weight: normal;
	z-index: 3;
	/* width: 1600px; */
	/* height: 111px; */
	/* background-color: #ebebeb; */
}



.niv_background {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.wrap_dipcolonne {
	height: auto;
	width: 100%;
}

.wrap_dipcolonne .itemcolone {
	width: 32%;
	background-color: #ef19cd;
	height: 30px;
	float: left;
}

.wrap_dipcolonne .colonne_sep {
	width: 2%;
	float: left;
	height: 30px;
}

.wrap_dipcolonne .colonne_middle {
	background-color: #75b70b;
}

.title_dip {
	padding: 12px;
	font-size: 33px;
	text-align: center;
	margin-top: 22px;
	font-weight: bold;
}

.diplome_img img {
	margin-top: 3%
}

.img_wrap_dip {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -2;
}

.title_dip span {
	color: #8ac29d;
}

.delivr {
	font-size: 20px;
}

.lequipe_dip {
	font-size: 25px;
	font-weight: bold;
	margin-top: 30px;
}

.a_dip {
	font-size: 20px;
	margin-top: 8px;
}

.dip_prenom {
	font-size: 26px;
	font-weight: bold;
	margin-top: 22px;
	color: #ef19cd;
}

.para_dip {
	text-align: left;
	padding: 33px 94px;
	font-size: 18px;
}

.wrap_dipcolonne1 {
	height: inherit;
	width: 100%;
	/*margin-top: 6%;*/
}

.wrap_dipcolonne1 .itemcolone {
	width: 32%;
	background-color: #ef19cd;
	height: 30px;
	float: left;
}

.wrap_dipcolonne1 .colonne_sep {
	width: 2%;
	float: left;
	height: 30px;
}

.wrap_dipcolonne1 .colonne_middle {
	background-color: #75b70b;
}

.para_dip  span {
	font-weight: bold;
}

.intitule_dip {
	color: #8ac29d;
	font-weight: bolder;
}
.sign_dip {
	padding-bottom: 29px;
	padding-top: 36px;
	font-size: 17px;
}
/*CSS POUR LE DIPLOME ALADI*/
.wrap_copi {
	margin-bottom: 1%;
	float: right;
	margin-left: 2%;
}

.wrap_copi input {
	height: 29px;
}

.text_copy {
	display: inline-block;
}

.button_copy {
	width: 29px;
	height: 29px;
	padding: 0px;
	background-color: #e2007a;
	border: none;
	color: white;
	line-height: 1em;
	font-weight: normal;
}

.confirm_copy {
	display: inline-block;
	font-weight: bold;
	color: #5cb85c;
	visibility: hidden;
}

.env_link_article {
	float: left;
	margin-right: 1%;
	margin-top: 9px;
}

/* .button_copy .glyphicon{ */
/* 	line-height: 2em; */
/* } */
.lin_art {
	background-color: #e2007a;
	width: 40px;
	height: 40px;
	text-align: center;
	font-size: 20px;
	color: white;
	float: left;
	margin-bottom: 1%;
}

.button_copy:hover {
	opacity: 0.5;
}

.wrap_diplo {
	display: none;
	position: absolute;
	top: 24%;
	left: 46%;
	width: 20%;
	height: 239px;
	background-color: royalblue;
	z-index: 10;
}

.tess:hover .wrap_diplo {
	display: block;
}

.sep_lit_livel span {
	font-size: 100px;
}

.hide_respons {
	display: none;
}

.result_wrap {
	width: 57%;
	text-align: right;
	float: left;
	color: #bf8970;
	font-weight: bold;
}

.result_wrap button {
	height: 26%;
	background-color: #e2007a;
	color: white;
	border: none;
	box-shadow: 2px 2px 2px #605b5b;
	/* width: 66%; */
	font-size: 0.9em;
}

.result_wrap img {
	width: 100%;
	/* box-shadow: 2px 2px 2px #0009; */
}

.wrap_hide {
	display: none;
	position: absolute;
	top: 249px;
	width: 26%;
	height: auto;
	background-color: #e2e6dc;
	z-index: 50;
}

.item_hide {
	width: 100%;
	border-bottom: 1px solid #bf8970;
	float: left;
	padding: 4%;
}

.item_hide_title {
	width: 43%;
	float: left;
	text-align: left;
	color: #444;
}

.only_connect {
	text-align: center;
	color: black;
	height: 308px;
	margin-bottom: 17%;
	margin-top: 43px;
	background-color: #e2e6dc;
	margin-left: -1%;
	margin-right: -1%;
}

.wrap_profil1 {
	/*!* display:none; *!*/
	/*position: absolute;*/
	/*left: 33%;*/
	width: 33%;
	margin: 0 auto;
	padding: 1%;
	color: #e2007a;
	text-align: center;
	font-weight: bold;
	margin-top: -45px;
	margin-bottom: 282px;

}

.tessi_last {
	background-color: #eff1ec;
	margin-top: 1%;
	height: 43px;
	float: left;
	width: 100%;
}

.tessi_last p {
	float: left;
	margin-top: 2%;
}

.tessi_last p:last-child {
	float: right;
	margin-right: 2%;
	color: #bf8970;
}

.tessi_last p:first-child {
	margin-left: 2%;
}

.user_petit {
	/* background-color: #bf8970; */
	width: 50%;
}

.user_grand {
	background-color: #eff1ec;
	width: 50%;
}

.tess {
height: 234px;
float: left;
}

.tessi {
	padding-bottom: 10px;
}

.donne_profil {
	float: left;
	padding: 1%;
	margin-left: 2%;
	border-right: 1px solid #8c7b7b66;
	margin-top: 2%;
	height: 80%;
	width: 17%;
	padding-top: 2%;
}

.donne_p {
	font-size: 18px;
	color: #444;
	height: 50%;
	margin-bottom: 15px;
}

.donne_profil:last-child {
	border-right: none;
}

.donne_profil:first-child {
	margin-left: 4%;
}

.donne_t {
	color: #e2007a;
	font-size: 27px;
	height: 44%;
}

.donne_t a {
	color: #e2007a;
}

.donne_t a:hover {
	opacity: 0.5;
}

.label_img_article{
	display: block;
	width: 100%;
	float: left;
	height: auto;
}

#wrap_im {
	margin-bottom: 1%;
	display: block;
	float: left;
	width: 100%;
	margin-top: 50px;
	position: relative;
}

#col_name {
	color: red;
	font-weight: normal;
	font-size: 1.5em;
	margin-top: 10px;
	margin-bottom: 45px;
}

#savoir_plus {
	position: relative;
	margin-top: 12%;
	text-align: right;
	margin-right: 3%;
}

#savoir_plus a {
	color: #e2007a;
	margin-left: 6px;
}

.niv {
	position: absolute;
	color: white;
	left: 48%;
	top: 30%;
	font-size: 0.8em;

}

.user_petit div {
	height: 35%;
	margin-top: 0%;
}

.user_petit div:first-child {
	margin-bottom: 0%;
	margin-top: 4%;
}

.user_petit hr {
	margin-top: 0px;
	margin-bottom: 0px;
	border: 0;
	border-top: 1px solid #eee;
}

.user_petit p {
	color: white;
	margin-bottom: 3px;
}

.user_petit button {
	height: 20px;
	background-color: #e2007a;
	color: white;
	border: none;
	box-shadow: 2px 2px 2px #605b5b;
/* 	width: 38%; */
	font-size: 0.9em;
}

.user_petit img {
	width: 56%;
}

.little_level {
	position: relative;
	width: 13%;
	height: 42px;
	/* background-color: #bf8970; */
	float: left;
	margin-top: 18px;
}

.little_levelb {
	display: none;
}

.little_level_name {
	color: white;
	font-weight: bold;
	float: left;
	margin-top: 5px;
	font-size: 1.3em;
	margin-left: 8px;
}

.sep_lit_livel {
	width: 20%;
	height: 100%;
	border-left-color: white;
	float: right;
	border-left-style: dashed;
	border-width: 1px;
	background-color: #bf8970;
	padding-left: 4px;
}

.sep_lit_livel span {
	margin-right: 27%;
	font-size: 1.3em;
	line-height: 41px;
	color: white;
}

.little_donne {
	position: absolute;
	/* color: #bf8970; */
	left: 16px;
	z-index: 1;
	top: 17px;
	font-size: 9px;
	font-weight: bold;
}

/*FIN CSS POUR LES DONNES DU DIPLOME ET PROFIL*/
.fond_black_nir {
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #00000080;
	z-index: 1000;
}

#ascenceur {
	position: fixed;
	bottom: 20px;
	right: 25px;
	width: 40px;
	height: 50px;
	background-color: #e2007a;
	vertical-align: center;
	text-align: center;
	display: none;
	border: 3px double white;
	padding: 4px;
}



.wrap_button {
	border-top: 1px solid #4444;
	padding-top: 1%;
	margin-top: 1%;
}



.soustitre {
	color: #e2007a;
	font-weight: bold;
}

.titre {
	display: inline-block;
	font-weight: normal;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 5px;
	text-decoration: none;
	color: #e2007a;
	margin-bottom: 30px;
	font-size: 30px;
}

.rslides {
	position: absolute;
	left: 0;
	top: 0%;
	height: 100%;
	/*     overflow: hidden; */
	width: 100%;
	padding: 0;
	margin: 0;
	margin-bottom: 0px;
	z-index: -5;
	overflow: hidden;
}


#header {
	/*     background: white; For browsers that do not support gradients */
	/*     background: -webkit-linear-gradient(gray, white); For Safari 5.1 to 6.0 */
	/*     background: -o-linear-gradient(gray, white); For Opera 11.1 to 12.0 */
	/*     background: -moz-linear-gradient(gray, white); For Firefox 3.6 to 15 */
	/*     background: linear-gradient(gray, white); Standard syntax (must be last) */
	/*font-family: Solano Gothic MVB Lt, sans-serif;*/
	box-shadow: 0px 0px 0px white;
	padding: 0px;
	line-height: 1.5;
	margin-top: -12px;
	font-style: italic;
	font-size: 1em;
	/* 	border-bottom: 1px solid #eee; */
	height: 34px;
	margin-top: 4px;
}

.wrap_accroche {
	display: none;
	position: absolute;
	left: 31%;
	width: 38%;
	padding: 1%;
	color: #e2007a;
	background-color: rgba(255, 255, 255, 0.6);
	font-size: 2em;
	text-align: center;
	font-weight: bold;
	top: 260px;
	/* 	z-index: 2; */
	border: 6px double white;
}

.wrap_user {
	text-align: right;
	/* 	margin-right: 0; */
	margin-top: -1em;
	margin-bottom: 1em;
}

.bienvenue_user {
	color: white;
	font-size: 25px;
	background-color: #e2007a;
	padding: 2px 5px 2px 5px;
	font-weight: bold;
	border: 2px solid white;
}

.bienvenue_user:first-child:hover {
	/* 	color: white; */
	cursor: pointer;
	color: #e2007a;
	background-color: white;
	border: 2px solid #e2007a;
}

.connect_user {
	display: none;
}

.connect_user span {
	font-size: 17px;
}

.connect_user:hover {
	cursor: pointer;
	color: #e2007a;
	background-color: white;
	border: 2px solid #e2007a;
}

.bienvenue{
	color: #e2007a;
	padding: 9px;
	font-size: 1.5em;
	background-color: #ffffffad;
}

.bienvenue:hover{
	color: #e2007a;
}

/* nav{ */
/* 	line-height :3; */
/* } */
.topbarcontainer {
	background-color: transparent;
	margin-top: -20px;
	color: black;
}

/*((((((((((((((((((((((((((((((((( ))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))*/
/*(((((((((((((((((((((((((((((((((  LES CLASSES CREES LE 24/10/2018 ALADI  ))))))))))))))))))))))))))))))*/
/*((((((((((((((((((((((((((((((((()))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))*/


#label_switch {
	margin-left: 385px;
}

.blog {
    background-color: #e2e4e5;
    height: 46em;
    overflow-y: scroll;
    padding: 30px;
}

#new_consentement .modal-dialog .modal-content .modal-body .blog {
	height: calc(98vh - 30vh);
	overflow-y: scroll;
	background-color: #f0f0f0;
	padding: 23px;
	margin-bottom: 10px;
}

#infoConsentement_button {
    position: relative;
    bottom: 30px;
}

.modal-body form #dataConsentement_button {
    position: relative;
    bottom: 40px;
}

.modal-content1 {
	width: 69%;

}

.texte_li {
	color: white;
	font-size: 1.2em;
	/* line-height: 6em; */
	line-height: 2em;
}

.wrap_content .item_top {
	color: white;
	/*     padding: 2px 12px 0px 10px; */
	height: 100%;
	text-align: right;

}

.item_top a {
	color: #e2007a;
	font-weight: bold;
	font-size: 1.4em;
	float: left;
	margin-left: 5px;
}

.item_top a img {
	opacity: 0.5;
}

.item_top a img:hover {
	opacity: 1;
}

.wrap_content {
	float: right;
	margin-top: 10px;
	height: 100%;
	width: auto;
	margin-right: 2%;
}

.icone_li {
	color: white;
}

.cone_inscrip_non_inscris{
	position: relative;
	margin-top: 15%;
	background-color: transparent;
	padding: 51px;
	height: auto;
	margin-bottom: 4%;
}

.cone_inscrip {
	position: relative;
	margin-top: 15%;
	background-color: transparent;
	padding: 51px;
	height: auto;
	margin-bottom: 4%;
}

.col_new {
	width: 33%;
	margin-left: 0px;
	float: left;
}

.col_new a {
	font-size: 1.3em;
}

.col_new a:hover {
	background-color: white;
	color: #e2007a;
	/* Firefox */
	-moz-transition: all 0.5s ease-out;
	/* WebKit */
	-webkit-transition: all 0.5s ease-out;
	/* Opera */
	-o-transition: all 0.5s ease-out;
	/* Standard */
	transition: all 0.5s ease-out;
}

.col_new a:hover .bi {
	color: #e2007a;
	/* Firefox */
	-moz-transition: all 0.5s ease-out;
	/* WebKit */
	-webkit-transition: all 0.5s ease-out;
	/* Opera */
	-o-transition: all 0.5s ease-out;
	/* Standard */
	transition: all 0.5s ease-out;
}

.init_row {
	margin-top: 2%;
}

.init_pass {
	margin-left: 36%;
	width: 30%;
}

.init_pass a {
	background-color: #fbe6f3;
	color: #e2007a;
	/* 	border: 1px solid #e2007a; */
}

.init_pass a span {
	color: #e2007a;
}

.init_pass a:hover {
	background-color: #e2007a;
	color: #fbe6f3;
	/* Firefox */
	-moz-transition: all 0.5s ease-out;
	/* WebKit */
	-webkit-transition: all 0.5s ease-out;
	/* Opera */
	-o-transition: all 0.5s ease-out;
	/* Standard */
	transition: all 0.5s ease-out;
}


.init_pass a:hover .bi{
	color: #fbe6f3;
	/* Firefox */
	-moz-transition: all 0.5s ease-out;
	/* WebKit */
	-webkit-transition: all 0.5s ease-out;
	/* Opera */
	-o-transition: all 0.5s ease-out;
	/* Standard */
	transition: all 0.5s ease-out;
}

/*#myNavbar {*/
/*	box-shadow: 2px 2px 2px #444;*/
/*}*/

#sec2 {
	text-align: center;
	color: black;
	height: 323px;
	margin-bottom: 17%;
	margin-top: 115px;
	background-color: #e2e6dc;
	margin-left: -1%;
	margin-right: -1%;
}

#sec2 article {
	display: inline-block;
	width: 19%;
	margin: 3%;
	float: left;
	/*     border: 1px solid rgba(0,0,0,0.2); */
	/* 	padding: 2%; */
	height: 87%;
	color: #444;
}

#sec2 article blockquote {
	padding: 25px 15px;
	margin: 0 0 20px;
	font-size: 1.2em;
	border-left: none;
}
.tiragequest {
	margin-left: auto;
	margin-right: auto;
	max-width: 448px;
	border: 1px solid blanchedalmond;
	padding: 10px;
	position: relative;
	background-color: #d9edf7;
	margin-bottom: 12px;
}
.tiragequest h3 {
	margin-top:2px;
}
.tiragequest .user_petit {
    margin-left: 28%;
}
.number_user {
	font-size: 1.2em;
	color: white;
	background-color: #e2007a;
	height: 30%;
	width: 35%;
	display: block;
	border-radius: 50%;
	margin-left: 30%;
	line-height: 4.8em;
	font-weight: bold;
}

.icone_sec2 {
	width: 100%;
}

.last1 {
	padding-top: 20px;
}

#sec2 article:hover {
	color: #e2007a;
	/* 	background-color: #d6eab7; */
}

.wrap_h_old {
	/* 	position: absolute; */
	width: inherit;

	/*top: 0;*/
	/*left: 0;*/
	z-index: -1;
}

.wrap_h_old img {
	width: 100%;
	/* 	-webkit-filter: blur(2px); */
	/*     filter: blur(2px); */
}

.menubouton b {
	/* 	font-family: "Open Sans"; */
	
}

/*((((((((((((((((((((((((((((((((((((((((((((( ))))))))))))))))))))))))))))))))))))))))))))))))))))))))))*/
/*(((((((((((((((((((((((((((((((((((((((  FIN DES CLASSES NOUVELLEMENT CREES )))))))))))))))))))))))))))))))))))))))))*/
/*((((((((((((((((((((((((((((((((()))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))*/
.topbar {
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	text-align: left;
	display: flex;
	min-height: 40px;
	padding: 15px 15px 15px 15px;
	max-height: 60px;
}

.nav-link .lien_actif {
	background-color: #0000004d;
	color: red;
	font-weight: bold;
}

/* #bar { */

/* } */
#header a {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

.login.container {
	padding-bottom: 15px;
	padding-left: 0;
	padding-right: 0;
}

.login .btn {
	font-weight: bold;
	font-size: medium;
	color: white;
	box-shadow: 1px 1px 6px black;
	background-color: black;
	padding: 10px;
}

.login .btn:hover {
	box-shadow: 3px 3px 12px white;
}

.login .fontspantitle {
	padding-top: 0px;
}

.login .fontspan {
	padding-top: 10px;
	text-align: right;
}

.login .btnlogin {
	padding-right: 0px;
}

.login .etude {
	color: black;
	font-size: 1.2em;
	line-height: 1;
}

.login .slogan {
	color: #3c3b3b;
	font-size: 1em;
	line-height: 1;
}

.login .hello {
	color: black;
	text-align: right;
}

.login .label {
	padding: 0px;
}

.lightblue {
	color: blue;
}

.lightblue:hover {
	color: #bcd5ec;
}

.loaderImage {
	position: fixed;
	z-index: 999;
	height: 5em;
	width: 5em;
	overflow: auto;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.loaderImage:before {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
}

.panel-body-nutrinet {
	padding: 0px;
}

.fb-share-button {
	margin-right: 15px;
}

.twitter-share-button {
	margin-right: -10px;
}

.item-news-title>a {
	text-decoration: none;
}
.close{
	border: none;
	background: transparent;
	color: black;
	font-weight: bold;
	display: block;
	float: right;
	font-size: 21px;
}

.body {
	text-align: justify;
}
.modal-dialog {
	width: 95%;
	max-width : none;
}
.bodyPop {
	background-color: white;
}

.closePop {
	background-color: #b1c800;
}

.headerPop {
	background-color: #bcd5ec;
}

.footerPop {
	background-color: #bcd5ec;
}
/* Redband alert */
.redband {
	padding: 10px;
	background-color: #ea1823;
	color: white;
	font-weight: bold;
	margin-bottom: 15px;
	border-color: #ea1823;
	border-style: solid;
	border-width: 5px;
	border-radius: 0;
}

.redband a {
	color: white;
	text-decoration: underline;
}

/* The alert message box */
.alertadmin {
	padding: 10px;
	background-color: #e2007a;
	color: white;
	font-weight: bold;
	margin-bottom: 15px;
	border-color: #e2007a;
	border-style: solid;
	border-width: 5px;
	border-radius: 0;
}

.alertadmin2 {
	padding: 8px;
	background-color: #e2007a;
	color: white;
	font-weight: bold;
	border-color: #e2007a;
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	z-index: 10;
	position: absolute;
	width: 92.1%;
	left: 3.4%;
	top: 60%;
}

/* The close button */
.closebtnadmin {
	margin-left: 15px;
	color: white;
	font-weight: bold;
	float: right;
	font-size: 30px;
	line-height: 58%;
	cursor: pointer;
	transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
	color: black;
}

.btn-primary.fv {
	color: #000;
	background-color: #fff;
	border: none;
	border-radius: 6px;
	font-weight: normal;
	padding: 3px;
}

.btn-primary.fv:hover {
	color: #2e6da4;
	background-color: #fff;
	border: none;
}

.caption {
	text-align: center;
	font-weight: normal;
	font-size: 0.9em;
}


.partenaire img{
	margin-bottom: 5%;
}

.partenaire {
	min-height: 150px;
	margin: 15px;
	text-align: center;
}

.carousel-control {
	color: #3399cc;
	opacity: 1;
	margin-left: -70px;
	width: 0px;
}

.carousel-indicators {
	margin-bottom: -50px;
}

.carousel-caption {
	text-shadow: 0 1px 1px rgba(122, 115, 115, 0.6);
}

.carousel-indicators li {
	background-color: #3399cc;
	opacity: 1;
	border-color: white;
}

.carousel-indicators .active {
	background-color: #3399cc;
	border-color: #3399cc;
	opacity: 0.1;
}

.carousel-control:hover {
	color: #66cc00;
}

.carousel-control:focus {
	color: #66cc00;
}

.carousel-control.left {
	background-image: -webkit-linear-gradient(left, rgba(102, 204, 0, 0) 0%,
		rgba(102, 204, 0, 0) 100%);
	background-image: -o-linear-gradient(left, rgba(102, 204, 0, 0) 0%,
		rgba(102, 204, 0, 0) 100%);
	background-image: linear-gradient(to right, rgba(102, 204, 0, 0) 0%,
		rgba(102, 204, 0, 0) 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66CC00',
		endColorstr='#00000000', GradientType=1);
	margin-left: -18px;
	left: 65px;
}

.carousel-control.right {
	left: auto;
	right: 65px;
	background-image: -webkit-linear-gradient(left, rgba(102, 204, 0, 0) 0%,
		rgba(102, 204, 0, 0) 100%);
	background-image: -o-linear-gradient(left, rgba(102, 204, 0, 0) 0%,
		rgba(102, 204, 0, 0) 100%);
	background-image: linear-gradient(to right, rgba(102, 204, 0, 0) 0%,
		rgba(102, 204, 0, 0) 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',
		endColorstr='#66CC00', GradientType=1);
	right: 70px;
}

.article.encadre {
	border: 1px solid #ddd;
	/* 	padding: 0 10px 10px; */
}

.article.encadre .body {
	overflow: hidden;
	padding: 0 15px;
}

.article.encadre .body .right {
	margin-bottom: 10px;
}

.article.encadre .h4 {
	margin-top: 0px;
}

.more {
	background-color: #f5f5f5;
	cursor: pointer;
	text-align: center;
	font-size: 15px;
	padding: 5px 0;
}

.three_dot {
	font-size: 20px;
	text-align: center;
}

.panel-nutrinet {
	border-color: white;
	border-radius: 0px;
	/*padding: 1%;*/
	/*background: #f9f9f9;*/
}

.panel-primary>.panel-news {
	/*background-color: #d6eab7; */
	background: -moz-linear-gradient(right, #ffffff 0%, #d6eab7 90%, #d6eab7 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(right, #ffffff 0%, #d6eab7 90%, #d6eab7 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to left, #ffffff 0%, #d6eab7 90%, #d6eab7 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	border-color: white;
	border-radius: 0px;
}

.panel-head-nutrinet {
	border-color: #d6eab7;
	border-radius: 0px;
	border: 0px;
	box-shadow: 0 0px 0px;
}

.panel-news>.panel-title {
	color: black;
	font-size: 1.5em;
}

.item-news-title {
	padding-top: 10px;
	padding-bottom: 140px;
	color: #3399cc;
	text-align: center;
}

.center {
	align: center;
	text-align: center;
}

.item-news {
	padding-bottom: 20px;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: -5%;
	height: 200px;
}

#bottom {
	background: white;
	text-align: center;
	margin-top: 0px;
	box-shadow: 0px 0px 5px white;
	padding: 0px 5px 0px 5px;
	margin-left: 0px;
	margin-right: 0px;
	padding: 1px;
}

#menu {
	background-color: white;
	/*font-family: Solano Gothic MVB Lt, sans-serif;*/
	text-transform: none;
	padding: 2px;
	padding-top: 1em;
}

#menu a {
	color: #3399cc;
	font-weight: bold;
	padding-left: 1px;
	padding-right: 1px;
	font-size: 1em;
}

.menu_Show_onglets {
	background-color: white;
	/*font-family: Solano Gothic MVB Lt, sans-serif;*/
	text-transform: none;
	padding: 2px;
	padding-top: 1em;
}

.menu_Show_onglets a {
	font-weight: bold;
	color: #3399cc
}

.onglets /* Ces classes permettent de créer des onglets */ {
	list-style-type: none;
	padding-bottom: 1px;
}

.onglets li {
	float: right;
	height: 23px;
	background-color: #F4F9FD;
	margin: 2px 2px 0 2px !important;
	border: 1px solid gray;
	border-bottom: 1px solid #e2007a;
}

.onglets li.onglet_actif {
	border-color: #e2007a;
	border-bottom: white;
	background-color: #fff;
}

.onglets a {
	padding: 3px;
	padding-top: 2px;
	padding-left: 4px;
}

.barre {
	border-bottom: 1px solid #e2007a;
	height: 39px;
	width: 100%;
}

.homewelcome {
	font-size: 2em;
	background: white;
	padding: 0px;
}

.tuileHome {
	display: block;
	position: relative;
	margin-bottom: 0px;
	margin-top: 15px;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
	vertical-align: middle;
	min-height: 180px;
	max-height: 180px;
	box-shadow: 0 0 10px #ccc;
}

.img-responsive.center {
	margin-left: auto;
	margin-right: auto;
}

.nutrinautes {
	display: block;
	position: absolute;
	z-index: 1;
	top: 25%;
	left: 5%;
	width: 90%;
	height: 50%;
	color: #e2007a;
	font-size: 2em;
	/*font-family: sans-serif;*/
	font-weight: bold;
}

.tuileTexte {
	display: block;
	position: relative;
	padding-top: 5px;
	font-size: 1em;
}

/*((((((((((((((((((((((((((((((((((((((((( CE CODE EST TIREE DU NOUVELLE LAYOUT )))))))))))))))))))))))))*/
#actualites {
	width: 100%;
	min-height: auto;
	margin-top: 5%;
	/* 	margin-bottom: 10%; */
}

.sec {
	font-size: 2.5em;
	text-align: center;
	letter-spacing: 2px;
	margin: 0;
	padding: 0;
	color: #e2007a;
	margin-bottom: 50px;
	font-weight: 300;
}

.seperateur {
	text-align: center;
}

.seperateur img {
	width: 30%;
}

#actualites article h1:hover {
	color: black;
}

#actualites article {
	width: 47%;
	float: left;
	margin-left: 2%;
	border: 1px solid #E2007A;
	padding: 1%;
	/* 	moz-box-shadow: 3px 3px 5px 0px #656565; */
	/* 	-webkit-box-shadow: 3px 3px 5px 0px #656565; */
	/* 	-o-box-shadow: 3px 3px 5px 0px #656565; */
	/* 	box-shadow: 3px 3px 5px 0px #656565; */
}

.consulter a:hover {
	color: black;
}

.wrap_article_img {
	width: 100%;
	overflow: hidden;
	border-bottom: 1px solid #E2007A;
}

.wrap_article_img img {
	width: 100%;
	-webkit-transition: all 2s;
	-o-transition: all 2s;
	-ms-transition: all 2s;
	-moz-transition: all 2s;
	transition: all 2s;
}

.title_news {
	background-color: #E2007A;
	float: left;
	width: 100%;
	border-bottom: 1px solid #E2007A;
	margin-bottom: 3%;
	min-height: 130px;
}

.title_news a {
	text-decoration: none;
}

/* .title_news a:hover { */
/*      color: white; */
/* } */
#actualites article h1 {
	font-size: 1.6em;
	color: white;
	padding: 4%;
	text-align: center;
	font-weight: normal;
}

#actualites article .body_article {
	width: 96%;
	background-color: white;
	text-align: left;
	min-height: auto;
	padding: 2%;
	text-align: justify;
	
}

#actualites article p {
	color: #444;
}

.consulter {
	background-color: #E2007A;
	/*     width: 34%; */
	text-align: center;
	padding: 1%;
	float: right;
}

.consulter a {
	text-decoration: none;
	transition: 0.2s;
	color: white;
	font-weight: bold;
}

#plusarticles {
	width: 16%;
	text-align: center;
/* 	margin: 0 auto; */
	font-size: 1.5em;
	padding: 10px;
	margin-bottom: 18%;
	margin-top: 50px;
	border: 2px solid #e2007a;
	background-color: white;
	color: #e2007a;
	color: white;
	display: inline-block;
	margin-left: 42%;
	margin-right: 42%;
	
}

#plusarticles a {
	text-decoration: none;
	color: #e2007a;
	/* 	font-weight: bold; */
}

#sec4 .part {
	width: 12%;
	display: inline-block;
	margin: 0.9%;
	vertical-align: middle;
	text-align: center;
}

#sec4 {
margin-bottom: 0;
display: block;
float: left;
width: 100%;
}

/*LES HOVERS*/
.wrap_article_img img:hover {
	transform: scale(1.2, 1.2);
	-ms-transform: scale(1.2, 1.2);
	-webkit-transform: scale(1.2, 1.2);
	-o-transform: scale(1.2, 1.2);
	-moz-transform: scale(1.2, 1.2);
}

/*((((((((((((((((((((((((((((((((((( CE CODE EST TIREE DU NOUVELLE LAYOUT )))))))))))))))))))))))))*/
/*////////////////////////////////*/
/*////////////FLECHE ICONE////////*/
/*////////////////////////////////*/
.seperateur {
	width: 45%;
	height: 50px;
	margin: 0% 26% 1% 31%;
}

.seperateur .firsthr, .lasthr {
	/* 	position:relative; */
	float: left;
	width: 30%;
	height: 20px;
	margin-left: 5%;
	margin-right: 5%;
	margin-top: -5px;
}

.seperateur hr {
	/* 	position: absolute; */
	/* 	top:2; */
	/* 	left:0; */
	border: none;
	border-bottom: 1px solid #e2007a; /* Ou border-top */
}

.sqare {
	width: 30px;
	height: 30px;
	background-color: #e2007a;
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	float: left;
	border: 6px double white;
}

.sqare:hover {
	background-color: white;
	border: 6px double #e2007a;
}



#ftr {
	background-color: #373737;
	padding: 5% 0% 5% 0%;
	width: 100%;
	display: inline-block;
	border-top: 16px solid #e2e6dc;
	vertical-align: baseline;
	margin-top: 200px;
	
}


#wrap_footer_content {
	padding-bottom: 6%;
	border-bottom: 1px dashed #4c4c4c;
}

#ftr article {
	display: inline-block;
	vertical-align: top;
	margin-left: 13%;
	width: 15%;
	border-right: 1px solid #e2007a;
}

.h2-zone {
	margin: 2px;
	font-size: 21px;
	font-weight: bold;
/* 	color: #e2007a; */
}

#ftr article h2 {
	font-size: 2em;
	color: #e2007a;
	margin-bottom: 10px;
	/*font-family: 'Open Sans', sans-serif;*/
	font-weight: normal;
	margin-top: 0px;
}

#ftr article ul {
	list-style-type: none;
	padding-left: 0px;
}

#ftr article a {
	list-style-type: none;
	text-decoration: none;
	color: white;
	line-height: 30px;
	font-size: 1.3em;
}

#ftr img {
	vertical-align: middle;
	margin-right: 3%;
	margin-bottom: 2%;
	opacity: 0.7;
}

#ftr article a:hover {
	color: #e2007a;
}

/*((((((((((((((((((((((((((((((((((((((((((( CSS DU SYSTEME DE STOCK ET UPLOAD IMAGE ))))))))))))))))))))))))))))))))))))))))))))))))))))))))))*/
.browser {
	display: block;
	position: relative;
	margin: 0 auto;
	margin-top: 1%;
	width: 90%;
	background-color: #fbe6f3;
	padding: 5%;
	padding-top: 20px;
	text-align: center;
	height: 500px;
	overflow: auto;
	margin-bottom: 1%;
	margin-top: 0px;
	-webkit-box-shadow: inset 0px 0px 8px 0px rgba(97, 95, 97, 1);
	-moz-box-shadow: inset 0px 0px 8px 0px rgba(97, 95, 97, 1);
	box-shadow: inset 0px 0px 8px 0px rgba(97, 95, 97, 1);
}

#wrap_img {
	margin-bottom: 3%;
}

.browser h1 {
	/*font-family: sans-serif;*/
	font-size: 25px;
	color: white;
	text-align: center;
	background-color: #E2007A;
	width: 50%;
	padding: 1%;
	margin: 0 auto;
	margin-bottom: 2%;
	font-weight: bold;
}

.browser img {
	margin-left: 3%;
	margin-right: 1%;
	margin-top: 3%;
	cursor: zoom-in;
}

.porte {
	position: absolute;
	background-color: red;
	height: 30%;
	margin: 0 auto;
	display: none;
}

/*#wrap_img{
    position: relative;
}*/
.ter {
	display: none;
	position: absolute;
	color: red;
	width: 100%;
	height: 100%;
	left: 0%;
	top: 0%;
	background-color: #00000066;
	position: fixed;
	z-index: 100;
}

.fermer {
	position: absolute;
	right: 29%;
	top: 21%;
	font-size: 30px;
	color: white;
	background-color: #E2007A;
	/* font-family: monospace; */
	width: 50px;
	height: 50px;
	text-align: center;
	border-radius: 50%;
	line-height: 164%;
	font-weight: bold;
	cursor: pointer;
}

#btl {
	width: 15%;
	font-size: 15px;
	padding: 5px;
	margin-bottom: 2%;
}

#btval {
	width: 10%;
	font-size: 18px;
	padding: 5px;
	margin-bottom: 2%;
	margin-right: 5%;
	float: right;
}

#mysmall {
	/*font-family: sans-serif;*/
	font-size: 20px;
	color: #E2007A;
	font-weight: bold;
	margin-bottom: 2%;
}

.browser  .a {
	width: 20px;
	height: 20px;
	display: inline-block;
	line-height: -200px;
}

.browser  .a {
	width: 20px;
	height: 20px;
	display: inline-block;
	line-height: -200px;
}

.browser::-webkit-scrollbar {
	width: 10px;
}

.browser::-webkit-scrollbar-track {
	
}

.browser::-webkit-scrollbar-thumb {
	background-color: #E2007A;
}

.browser::-webkit-scrollbar-button {
	background-color: black;
}

.browser::-webkit-scrollbar-corner {
	background-color: black;
}

.val_file {
	margin-right: 2%;
}

.wrap_form_file {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: #00000080;
	z-index: 1000;
	display: none;
}

.upload {
	position: absolute;
	left: 40%;
	top: 30%;
	width: 20%;
	background-color: #fbe6f3;
	padding: 2%;
}

.add_img {
	font-weight: bold;
	margin-bottom: 1%;
	margin-top: 2%;
	float: right;
	margin-right: 5%;
}


.tuileHome:hover {
	box-shadow: 0 0 10px #66cc00;
}

.statistique_campagne .bi {
	margin: 0;
	top: 0;
}

.scroll-div2>.table>thead>tr>th {
	white-space: nowrap;
}

.wmd-view-topscroll, .wmd-view {
	overflow-x: scroll;
	overflow-y: hidden;
	width: 100%;
}

.wmd-view-topscroll {
	height: 20px;
}

.scroll-div1 {
	height: 20px;
}

.archive_quest td.quest_libelle {
	width: 60%;
}

.table-archive {
	padding: 15px;
	border: 1px solid #ddd;
	border-radius: 4px;
	display: block;
	margin: 20px 0px  20px 0px;

}

table.statistique_campagne {
	font-size: 0.9em;
}

table.statistique_campagne td.info, .green {
	background-color: #B0C800 !important;
}

table.statistique_campagne td.relance, .red {
	background-color: #E20078;
}



.statistiques h4, .courants h4 {
background-color: #e2007a !important;
padding: 0.7em;
color: white;
font-weight: bold;
}


.courants{
margin-top: 4%;
}

.btn-number {
	border-radius: 0;
	background: white;
	border-color: #d6eab7;
	border-style: solid;
	padding: 15px;
	cursor: unset;
	text-transform: uppercase;
	font-weight: bold;
}

.btn-inscription {
	display: block;
	position: relative;
	border-radius: 0;
	background: #e2007a;
	color: white;
	min-height: 75px;
	text-align: center;
	padding: 26px;
	font-weight: bold;
	border: 2px solid white;
}

.btn-inscription:hover {
	border-radius: 0;
	color: #ffffff;
	border: 2px solid #e2007a;
}

.btn-quest {
	background: #e2007a;
	border-color: #e2007a;
	color: white;
}

.btn-quest a {
	color: white;
}
.btn-quest a:hover{
	color: #e2007a;
}

.btn-quest:hover{
	color: #e2007a;
	background: white;
	border-color: #e2007a;
}

.logintable {
	padding: 10px;
}

.nav-stacked li {
	background-color: #F2F2F2;
}

#content {
	background-color: transparent;
	padding:0;
/* 	padding-top: 1em; */
	height: auto;
	/*     border-left : #F2F2F2 solid 1px; */
	/*     background-color: #4178AE; */
/* 	padding-bottom: 20px; */
	/*     background-image: url(../images/background.jpg); */
	/*     background-repeat: repeat-x; */
	color: #404040;
	position: inherit;
/* 	width: 98%; */
	padding-left: 15px;
	padding-right: 15px;
}

#foot {
	display: block;
	position: relative;
	text-align: left;
	background-color: black;
	font-weight: bold;
	padding-top: 5px;
	margin-bottom: -10px;
}

#foot a {
	color: gray;
}

#foot a:hover {
	color: #babdb6;
}

#foot ul.row {
	color: white;
	font-size: 12px;
}

#foot ul.list_menu_footer {
	font-size: 11px;
}

#body-content {
	margin-top: 0px;
	margin-bottom: 0px;
	box-shadow: 0px 0px 5px white;
	padding: 0px 5px 5px 5px;
	background-color: white;
}

body {
	background: #ffffff; /* Old browsers */
	/*     background: -moz-linear-gradient(top, #ffffff 0%, #75b70b 42%, #75b70b 100%); FF3.6-15 */
	/*     background: -webkit-linear-gradient(top, #ffffff 0%,#75b70b 42%,#75b70b 100%); Chrome10-25,Safari5.1-6 */
	/*     background: linear-gradient(to bottom, #ffffff 0%,#75b70b 42%,#75b70b 100%); W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	width: 100%;
	/*     background: url(../images/new_images/image_header.jpg) no-repeat; */
	/*     background-size: contain; */
}

.theme_preprod {
	background-color: #3399cc !important;
}

.theme_demo {
	background-color: lightslategray !important;
}

.theme_test {
	background-color: purple !important;
}

.theme_locale{
	background-color: #75b70b !important;
}

html {
	/* 	background-color: black; */
	overflow-x: hidden;
	width: 100%;
}

.hide {
	visibility: hidden;
	display: none;
/* 	display: inherit; */
}

.show {
	/*     visibility : hidden; */
	display: block;
	/*     display: inherit; */
}

.body-article {
	padding: 1%
}

.xsmall {
	width: 15%;
	display: inline-table;
	margin-left: 5px;
}

fieldset {
	margin-top: 10px;
	margin-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	border: #3399CC 1px solid;
}

legend {
	font-size: 1em;
	color: #3399CC;
	padding-right: 10px;
	padding-left: 5px;
	padding-bottom: 0;
	margin-bottom: 0;
	display: block;
	border: none;
	width: auto;
}

.td_label {
	width: 15em;
	font-weight: bold;
}

.flash-notice {
	background-color: #d9edf7;
	border-color: #bce8f1;
	padding: 1em;
	text-align: center;
	z-index: 2000;
	border-width: 4px;
	position: absolute;
    top: 430px;
    left: 10%;
    right: 10%;
    font-weight: bold;
}

.flash-warning {
	color: #8a6d3b;
	background-color: #fcf8e3;
	border-color: #faebcc;
	padding: 1em;
	text-align: center;
	z-index: 2000;
	border-width: 4px;
	position: absolute;
    top: 430px;
    left: 10%;
    right: 10%;
    font-weight: bold;
}

.flash-error {
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1;
	padding: 1em;
	text-align: center;
	z-index: 2000;
	border-width: 4px;
font-weight: bold;
    position: absolute;
   top: 430px;
    left: 10%;
    right: 10%;
}

.required:after {
	content: " *";
	color: red;
}

.radio .required:after {
	content: "";
}

.draggable {
	cursor: move;
}

.element.draggable td ul {
	min-height: 1em;
}

#table_quest {
	width: 100%;
}

#table_quest .action {
	width: 16em;
}

#table_quest .partie {
	margin-left: 1em;
	background-color: #DDD !important;
}

#table_quest .question {
	margin-left: 2em;
	background-color: #D3D3D3;
}

tr.colonne_head th {
	border-bottom: 1px solid black !important;
	background-color: #DDD;
}

.libMin.question_echelle {
	margin-left: -20px;
}

.libMax.question_echelle {
	margin-left: 0;
	padding-left: 0;
}

.question_echelle {
	vertical-align: top;
}

.question_compalim {
	width: 80%;
	display: inline-block;
}

.autre_compalim {
	margin-left: 1em;
}

.autre_compalim input {
	border-radius: 4px;
	border: 1px solid #ccc;
	margin-top: 1em;
}

.question_compalim_bouton {
	background-color: #d6eab7;
	border: 1px solid gray;
	margin-left: 2em;
	padding: 0.4em;
	/*     vertical-align: 0.8em; */
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

.compalim_aide {
	font-weight: normal;
}

.choice_image_type {
	text-align: center;
	margin-left: 5%;
	padding-right: 1%;
	padding-left: 1%;
}

.choice_image_type img {
	display: inline;
	width: 75%;
}

.choice_image_type input {
	margin-top: 0.7em;
}

.radioinline .radio {
	display: inline;
	margin-left: 5%;
}

.right {
	float: right;
}

.left {
	float: left;
}

.bleuFonce_nutrinetTxt {
	color: #3399cc;
}

.rose_nutrinet {
	color: #e2007a;
}

.erreurParamsQA {
	float: left;
	color: red;
	display: none;
}

.table-striped .mes_quest:hover {
	background-color: #e9f5fa;
	cursor: pointer;
}

#show_quest_info {
	margin-bottom: 1em;
	background-color: #F5F5DC;
	padding: 0.5em;
}

#show_quest_info td {
	padding-right: 0.5em;
}

#show_quest_info table {
	margin-bottom: 1em;
}

/* #show_quest_info  */
.form_already_used {
	margin-top: 1em;
	background-color: #F08080;
	padding: 0.2em;
}

#show_quest_info .description {
	clear: both;
	background-color: #DAECBE;
	padding: 0.2em;
}

.partie_update_btn {
	margin-top: 0.2em;
}

.btn_next {
	display: inline;
}

.btn_next>.btn-bilan {
	background-color: #d6eab7;
	border-color: #d6eab7;
	color: black;
}

.btn_previous {
	display: inline;
}

.reponse {
	padding-left: 1em;
	padding-right: 1em;
	background-color: #E4F3D1;
}

.container {
	width: 100%;
	position: relative;
	margin-bottom: 2%;
	z-index: 100;
}

.btn-secondary {
	background-color: #e2007a;
	border-color: #e2007a;
}

.btn-secondary:hover {
	color: #e2007a;
	background-color: white;
	border-color: #e2007a;
}


.bi {
	margin-right: 5px;
	margin-left: 5px;
}

.flash-noticeInscr {
    background-color: #d9edf7;
    padding: 1em;
    text-align: center;
    border-color: #bce8f1;
    font-size: 17px;
    border-width: 4px;
	color: #31708f;
    z-index: 1000;
    position: absolute;
    top: 430px;
    left: 10%;
    right: 10%;
    font-weight: bold;
}

.infobulle {
	display: inline-flex;
	/* Fonctionne pour tous les navigateurs à jour sauf safari */
	display: -moz-inline-box;
	/* Fonctionne pour les navigateurs edge et mozilla */
	display: -webkit-inline-box;
	/* Fonctionne pour les navigateurs chrome et safari */
}

/**************** Début espace santé ************************/
.inputTextTab {
	width: auto;
}

.es_head {
	color: #404040;
	background-color: #f9f9f9;
	font-weight: bold;
}

.es_entete {
	width: 30%;
}

.tr_lg_evt {
	background-color: #8ae234 !important;
}

/**************** Fin espace santé *************************/
table.elementTableau {
	/*     border: 1px solid black; */
	display: table !important;
	width: 100%;
	clear: both;
}

table.elementTableau tr.entete {
	border: 1px solid #e2007a;
	height: 2em;
}

table.elementTableau tr.entete td {
	border: 1px solid #e2007a;
	height: 2em;
}

table.elementTableau td.entete {
	border: 1px solid #e2007a;
	height: 2em;
}

table.elementTableau td {
	border: 1px solid black;
	height: 2em;
	width: 50px;
}

table.elementTableau td.disabled {
	background-color: gainsboro;
	height: 2em;
}

#masque {
	width: 100%;
	height: 100%;
	background-color: grey !important;
	opacity: 0.8;
	z-index: 50;
	position: fixed;
	top: 0;
	left: 0;
}

#popup_element {
	background-color: white;
	width: 50%;
	padding: 1em;
	z-index: 100;
	position: absolute;
	top: 15%;
	left: 25%;
	border: 1px solid black;
}

.ui-dialog-titlebar .ui-dialog-titlebar-close {
	float: right;
	height: 1em;
	width: 1em;
}

.ui-dialog-titlebar .ui-dialog-titlebar-close:before {
	/* 	content: "\2716";	 */
	
}

.ui-dialog-titlebar {
	/*     border: 1px solid #BDD5EC; */
	/*     background: #BDD5ED;   orangé */
	/*  	color: #ffffff; */
	border-radius: 4px;
	border-color: #3399cc;
	display: block;
}

.ui-dialog {
	position: fixed;
	z-index: 2000;
}

#popupconfirmation {
	background: #e2007a;
	text-align: center;
	padding: 20px;
	/* top: -1729.85px; */
	font-size: 15px;
	font-weight: bold;
	color: white;
	display: none;
}

.fond_popup {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0%;
	top: 0%;
	background-color: #00000066;
	position: fixed;
	z-index: 1000;
}

.ui-dialog-buttonpane {
	background: #EFF7E4;
	padding-bottom: 3em;
	padding-top: 0.5em;
}

.ui-dialog-buttonpane button {
	margin-left: 3em;
	-moz-user-select: none;
	background-image: none;
	border: 1px solid;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.42857;
	margin-bottom: 0;
	padding: 6px 12px;
	color: white;
	margin-right: 0px;
	font-weight: bold;
	background: #e20079;
	border-radius: 5px;
}

.ui-dialog-buttonpane button:hover {
	opacity: 0.5;
}

.ui-dialog-buttonset {
	padding-left: 21%;
	margin-top: 5%;
}

.form_tab_cell {
	margin: 2px;
	width: 90%;
	height: 90%;
	margin-left: 5%;
}

.ui-dialog-titlebar-close {
	display: none;
}

.addressnum {
	width: 19%;
	float: left;
}

.voie {
	width: 29%;
	float: left;
}

.Champs_voie_placeholder {
	font-weight: bold;
	font-style: italic;
	color: #999;
}

#Membre_way1.form-control option:first-child {
	font-weight: bold;
	font-style: italic;
	color: #999;
}

#Membre_way1.form-control option {
	font-weight: normal;
	font-style: normal;
	color: black;
}

.afteraddressnum {
	width: 50%;
}

.afteraddressnum2 {
	width: 80%;
}

.btn-toolbar {
	text-align: center;
}

.pagination {
	width: 100%;
	margin: 0 auto;
	padding: 1em;
	text-align: center;
}

.pagination-buttons {
	/*     width: 600px; */
	margin: 0 auto;
	padding: 1em;
	background: #f2f2f2;
	margin-bottom: 20px;
}

.page {
	display: inline-block;
	padding: 0px 9px;
	margin-right: 4px;
	border-radius: 3px;
	border: solid 1px #3399cc;
	background: #e9e9e9;
	box-shadow: inset 0px 1px 0px rgba(255, 255, 255, .8), 0px 1px 3px
		rgba(0, 0, 0, .1);
	font-size: .875em;
	font-weight: bold;
	text-decoration: none;
	color: #3399cc;
	text-shadow: 0px 1px 0px rgba(255, 255, 255, 1);
}

.page:hover {
	background: #fefefe;
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#FEFEFE),
		to(#f0f0f0));
	background: -moz-linear-gradient(0% 0% 270deg, #FEFEFE, #f0f0f0);
}

.current-page {
	display: inline-block;
	padding: 0px 9px;
	margin-right: 4px;
	border-radius: 3px;
	border: solid 1px #3399cc;
	background: #3399cc;
	box-shadow: inset 0px 0px 8px rgba(0, 0, 0, .5), 0px 1px 0px
		rgba(255, 255, 255, .8);
	font-size: .875em;
	font-weight: bold;
	text-decoration: none;
	color: #f0f0f0;
	text-shadow: 0px 0px 3px rgba(0, 0, 0, .5);
}

.accueil_recherche {
	margin-top: 5em;
	margin-left: 10em;
}

.accueil_recherche td {
	width: 25em;
	height: 10em;
	text-align: center;
	font-weight: bold;
	color: #3399cc;
	background-color: #f2f2f2;
	border: 3px solid white;
}

.nb_relance {
	color: #337ab7;
	cursor: pointer;
}

::-webkit-input-placeholder {
	font-style: italic;
	font-weight: bold;
}

:-moz-placeholder { /* older Firefox*/
	font-style: italic;
	font-weight: bold;
}

::-moz-placeholder { /* Firefox 19+ */
	font-style: italic;
	font-weight: bold;
}

:-ms-input-placeholder {
	font-style: italic;
	font-weight: bold;
}

.link {
	color: #337ab7;
}

.menu1 {
	/*border-bottom: solid 2px #337ab7;*/
	/* crée le trait noir en-dessous des onglets */
	padding-top: 8px; /* marge intérieure haute */
	padding-bottom: 5px;
	width: 100%;
}

#menu_groupe {
	margin: 0;
	padding: 0;
	list-style: none;
}

#menu_groupe li {
	display: inline; /* affichage horizontal */
	cursor: pointer;
}

#menu_groupe li a {
	padding: 6px 25px;
	margin: 0;
	background: #3399cc;
	color: #fff;
	border: 2px solid #337ab7;
	text-decoration: none;
	-moz-border-radius: 10px 10px 0 0;
	-webkit-border-radius: 10px 10px 0 0;
	border-radius: 10px 10px 0 0;
	-webkit-border-top-left-radius: 10px; /* pour Chrome */
	-webkit-border-top-right-radius: 10px; /* pour Chrome */
}

#menu_groupe #selected a {
	background: white;
	color: #3399cc;
	border-bottom: solid 2px white;
}

#menu_groupe .selected a {
	background: white;
	color: #3399cc;
	border-bottom: solid 2px white;
}

#menu_groupe li a:hover, #menu_groupe li a:focus, #menu_groupe li a:active
	{
	background: white;
	color: #3399cc;
	border-bottom: solid 2px white;
}

#result {
	width: 100%;
	display: table;
	position: relative; /* on positionne le conteneur */
	margin: 0 auto;
	height: 209px;
	/*height:10%;*/
	overflow: auto;
	scrollbar-3dlight-color: #318CE7;
}

#criterion {
	/*overflow: visible;*/
	width: 100%;
	/*position:relative;*/
}

.for_label {
	display: table-cell;
	/*width: 16em;*/
	width: 32%;
	font-weight: bold;
	margin: 2px 2px 2px 2px;
	float: left;
}

.for_crit {
	display: table-cell;
	/*width: 6em; */
	width: 12%;
	overflow: visible;
	margin: 2px 2px 2px 2px;
	float: left;
}

.for_rech {
	display: table-cell;
	overflow: visible;
	/*width: 26em;*/
	width: 52%;
	margin: 2px 2px 2px 2px;
}

.for_groupe {
	display: table-cell;
	width: 15%;
	overflow: visible;
	margin: 2px 2px 2px 2px;
}

.for_span {
	display: table-cell;
	overflow: visible;
	/*width: 2em;*/
	width: 3%;
	margin: 2px 2px 2px 2px;
}

.for_error {
	/*background-color: white;
    opacity: 0.7;*/
	font-style: italic;
	font-size: 10px;
	font-weight: bold;
	overflow: visible;
	position: absolute;
	display: table-row;
	margin-bottom: 100px;
	color: red;
}

.bulles {
	border: 2px solid #318CE7;
	border-radius: 10px 10px 10px 10px;
	margin: 5px 5px 5px 5px;
	padding: 10px 2px 10px 2px;
}

.text_crit {
	width: 80%;
	display: inline-table;
	margin: 2px 2px 2px 2px;
}

.date_crit {
	width: 200px;
	display: inline-table;
	margin: 2px 2px 2px 2px;
}

.crit {
	width: 95%;
	display: block;
	margin: 2px 2px 2px 2px;
}

.department {
	/*border: 2px solid black;*/
	width: 50%;
	display: inline;
	margin: 2px 2px 2px 2px;
}

.city {
	width: 40%;
	display: inline;;
	margin: 2px 2px 2px 2px;
}

.password_eye, .password_eye2 {
	right: 34px;
	cursor: pointer;
	position: relative;
	top: 0px;
}


.password_eyebis, .password_eye2bis {
	right: 34px;
	cursor: pointer;
	position: relative;
	top: 0px;
}

.selectline_small {
	width: 70%;
	display: inline;
	margin: 2px 2px 2px 2px;
}

.selectline_small2 {
	width: 70%;
	margin-left: 2px;
}

.selectline {
	width: 300px;
	display: inline;
	margin: 2px 2px 2px 2px;
}

.selectkind {
	width: 250px;
	display: inline;
	margin: 2px 2px 2px 2px;
}

/* hr {  */
/*     border: 0;  */
/*     height: 10px; */
/*     width: 100%; */
/*     rgba(0, 0, 0, 0.75) */
/*     background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #318CE7, rgba(0, 0, 0, 0));  */
/* } */
.answer {
	background-color: #EFEFEF;
	color: #318CE7;
	font-weight: bold;
	font-size: 17px;
	font-style: italic;
	text-decoration: blink;
	cursor: pointer;
	padding: 16px;
	margin-bottom: 10px;
}

ul .faq li div {
	margin: 10px 10px 10px 10px;
}

.faq > li {
	padding: 5px;
	list-style-type: none;
}

.response {
	/*display: none;*/
	
}

fieldset {
	display: block;
	margin-left: 2px;
	margin-right: 2px;
	padding-top: 0.35em;
	padding-bottom: 0.625em;
	padding-left: 0.75em;
	padding-right: 0.75em;
	border: 2px groove(internal value);
}

div .all div {
	display: inline;
	width: 100%;
}

li ul {
	padding: 3px;
	margin: 4px;
	margin-left: 30px;
}

ul.connectedSortable, ul.unSortable {
	padding: 3px;
	margin: 4px;
	margin-left: 30px;
}

ul.connectedSortable li.menulink, ul.unSortable li.menulink {
	list-style-type: none;
	min-height: 5px;
	min-width: 5px;
	background-color: #EFEFEF;
	color: #318CE7;
	font-weight: bold;
	font-size: 15px;
	font-style: italic;
	text-decoration: blink;
	padding: 1%;
}

ul.connectedSortable li.menulink:before, ul.unSortable li.menulink:before
	{
	content: " ⇢ "; /* on affiche une chaîne de caractère */
}

#sortable1, #sortable2, #sortable3 {
	margin: 10px;
}

div .ctn div {
	display: inline;
	width: 100%;
}

.validator {
	/*border: .5px solid #000;*/
	margin-left: 4em;
}



div .validator div {
	display: inline-block;
	margin-top: 0px;
	width: 8.1%;
	height: 8px;
	border: .5px solid #000;
	/*#F0FFFF*/
	border-radius: 2px 2px 2px 2px;
}

.small {
	width: 38%;
	display: inline-table;
}

div .dialog {
	background: #fff;
	background: -webkit-linear-gradient(top, #fff, #ddd);
	background: -moz-linear-gradient(top, #fff, #ddd);
	background: -ms-linear-gradient(top, #fff, #ddd);
	background: -o-linear-gradient(top, #fff, #ddd);
	background: linear-gradient(to bottom, #fff, #ddd);
	margin: 0 auto;
	width: 140px;
	padding: 50px 70px;
	text-align: center;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 10px solid #000;
	-webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
	-moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
}

div .dialog p {
	font-size: 120%;
	margin-bottom: 15px;
}

div .dialog a.button {
	margin: 0 1px;
	padding: 6px 15px 5px;
	font-weight: bold;
	font-size: 100%;
	color: #fff;
	text-align: center;
	border: none;
	border-right: 1px solid rgba(0, 0, 0, .2);
	border-bottom: 1px solid rgba(0, 0, 0, .2);
	background: #3d7cb1;
	background: -webkit-linear-gradient(top, #2cb0e5, #1a7cd3);
	background: -moz-linear-gradient(top, #2cb0e5, #1a7cd3);
	background: -ms-linear-gradient(top, #2cb0e5, #1a7cd3);
	background: -o-linear-gradient(top, #2cb0e5, #1a7cd3);
	background: linear-gradient(to bottom, #2cb0e5, #1a7cd3);
	text-shadow: rgba(0, 0, 0, .25) 1px 1px 1px;
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	border-radius: 13px;
	text-decoration: none;
}

div .dialog a.button:hover {
	background: #1e80bc;
	background: -webkit-linear-gradient(top, #26a0cd, #1661ab);
	background: -moz-linear-gradient(top, #26a0cd, #1661ab);
	background: -ms-linear-gradient(top, #26a0cd, #1661ab);
	background: -o-linear-gradient(top, #26a0cd, #1661ab);
	background: linear-gradient(to bottom, #26a0cd, #1661ab);
}

div .dialog a.button:active {
	background: #1e80bc;
}

span.heart {
	color: #c70000;
	font-size: 118%;
}

ul.ui-autocomplete {
	/* 	border: 1px solid black; */
	padding-left: 1em;
	width: 10em;
	clear: both;
	/* 	min-height:10%; */
	height: auto;
}

ul.ui-autocomplete li {
	list-style-type: none;
	background-color: #f1f1f1;
	padding-left: 1em;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
}

ul.ui-autocomplete li:first {
	background-color: red;
}

ul.ui-autocomplete li:hover {
	background-color: #e0e0e0;
}

.inverse-dropdown {
	background-color: #222;
	border-color: #080808;
	width: 250px;
	padding: 5%;
}

.inverse-dropdown li a {
	color: white;
}

.inverse-dropdown li a:hover {
	background-color: #e2007a;
	color: white;
}

.inverse-dropdown li a:focus {
	background-color: #000;
	color: white;
}

li.mine a.dropdown-toggle:hover {
	background-color: #0000004d;
}

li.mine:hover>:first-child {
	background-color: #000;
}

li.mine {
	
}

.crumb {
	background: transparent;
	padding: 0;
}

.crumb li {
	border-right: 1px solid #d0bfbf;
	padding-right: 10px;
	padding-left: 10px;
}

.glyph-nutrinet {
	font-size: 1.6em;
	line-height: 3em;
}

.menubouton>li>a {
	padding-bottom: 10px;
}

.menubouton > li {
	text-align: center;
	font-size: 13px;
	float: left;
	/*padding-top: 42px;*/
	/*padding-left: 2%;*/
	margin-left: 2%;
	height: 100%;
	padding-top: 2%;

}
.menubouton li:hover {
background-color:rgba(0,0,0,.3) ;
}



.menubouton li li {
	text-align: left;
	font-size: 13px;
	padding: 1%;
}

.menu_log {
	display: inline;
	min-width: 114px;
	font-size: 14px;
	margin-top: 15px;
	margin-right: 9px;
	right: 0px;
	max-height: 27%;
	background-color: #e2007a;
	font-weight: bold;
}

.twitter-share-button {
	position: relative;
	top: 34px;
}

/*a.navbar-brand {*/
/*	width: auto;*/
/*	margin-left: 2%;*/
/*	margin-top: 1%;*/
/*}*/

/*.navbar-nav>li>a {*/
/*	line-height: 25px;*/
/*	!*height: 155px;*!*/
/*}*/

.marge {
	float: left;
	padding: 0%;
	margin-left: -1%;
	margin-top: 0.3%;
}

.test font {
	position: relative;
	letter-spacing: -.3px;
}

checkline {
	display: inline;
	/* 	//width:65%; */
}

.logo {
	width: 7em;
	display: table-cell;
	margin-top: -25px;
	margin-left: -18px;
}

.lien_logo {
	float: left;
}

form .help-block {
	color: #a94442;
}

#render_info {
	margin-bottom: 1em;
}

#render_info h4 {
	color: #578b18;
	font-size: 20px;
	text-align: center;
}

#render_info h5 {
	color: #e2017b;
	font-size: 110%;
}
/**
 * Arborescence ...
 * ------------------------------
 */
.tree, .tree ul {
	margin: 0 0 0 1em; /* indentation */
	padding: 0;
	list-style: none;
	color: #369;
	position: relative;
}

.tree ul {
	margin-left: .5em
} /* (indentation/2) */
.tree:before, .tree ul:before {
	content: "";
	display: block;
	width: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	border-left: 1px solid;
}

.tree li {
	margin: 0;
	padding: 0 1.5em; /* indentation + .5em */
	line-height: 2em; /* default list item's `line-height` */
	font-weight: bold;
	position: relative;
}

.tree li:before {
	content: "";
	display: block;
	width: 10px; /* same with indentation */
	height: 0;
	border-top: 1px solid;
	margin-top: -1px; /* border top width */
	position: absolute;
	top: 1em; /* (line-height/2) */
	left: 0;
}

.tree li:last-child:before {
	background: white; /* same with body background */
	height: auto;
	top: 1em; /* (line-height/2) */
	bottom: 0;
}

.bleu-nutrinet {
background-color: #e2007a;
border-color: #e2007a;
font-weight: bold;
margin-top: 5px;

}

.bilan_only{
	position: absolute;
	z-index: 100;
	right: 94px;
	top: 7px;
}

.bleu-nutrinet1 {
background-color: #e2007a;
border-color: #e2007a;
font-weight: bold;
}



.bleu-nutrinet:hover {
	background-color: white;
	border-color: #e2007a;
	color:#e2007a;
}

.bleu-nutrinet1:hover {
	background-color: white;
	border-color: #e2007a;
	color:#e2007a;
}


.vert-fonce-nutrinet {
	border-color: #66cc00;
	background-color: #66cc00;
}

#checker {
	font-weight: bold;
	text-align: center;
}

.btn-popover{
	padding : 0;

}

.popover {
	font-size: 0.85em;
	border-color: white;
	overflow: auto;
}

.popover-title {
	color: white;
	font-size: 1em;
	border-color: white;
	background-color: black;
}

.stay_small {
	/* height: 275px; */
	/* background-color: red; */
	border: none;
	height: 83px;
}

.menubouton {
	margin-left: 1.6%;
	display: block;
	height: 100%;
}

.table-responsive>.table>tbody>tr>td {
	white-space: normal;
}

.table>tbody>tr>td {
	vertical-align: middle;
}

div.table-responsive {
	min-height: 0px;
}

div.table-responsive:after {
	min-height: 0px;
}

#content .btn-link {
	font-weight: bold;
	text-decoration: none;
	padding-top: 0;
	padding-bottom: 0;
	margin-left: -12px;
}

.inituser .btn-link {
	color: red;
	text-transform: uppercase;
}

#content .blue-qa {
	color: #3399cc;
	font-weight: bold;
}

#content .green-qa {
	color: #56ab00;
	font-weight: bold;
}

#content .grey-qa {
	color: #c0c0c0;
	font-weight: bold;
}

#content .rose-qa {
	color: #e2007a;
	font-weight: bold;
}

.table-responsive::-webkit-scrollbar {
	width: 5px; /*largeur de la scrollbar verticale*/
	height: 5px; /*hauteur de la scrollbar horizontale*/
	background-color: #000000;
}

.table-responsive::-webkit-scrollbar-track {
	background-color: #FFFFFF; /*couleur du fond de la scrollbar*/
}

.table-responsive::-webkit-scrollbar-thumb {
	/*la petite bande qui monte/descend*/
	background-color: #000000 /*couleur de l'ascenseur*/;
}




	

	
.article_limite{
	min-height: 300px;
}


.publication_article{
	font-style: italic;
	text-align: right;
	color: #e2007a;

}
	


.th_tr_archive th{
padding: 0.8%;
font-weight: 700;
background-color: #e2007a;
color: white;
font-size: 18px;
}


.quest_archives tr td{
	padding: 12px;
}



.blog_questconset .form-group{
	margin-bottom: 33px;
}


#wrap_compte_user {
/* 	background-color: #e2e4e5; */
	padding: 2%;
	display: block;
	float: left;
	margin: 0 auto;
	width: 100%;
	margin-top: 2%;
}

.utils_profil {
	background-color: white;
	width: 25%;
	float: left;
	height: auto;
	padding: 0%;
}

.profil_item {
/* background-color: blue; */
	width: 72%;
	float: left;
	height: max-content;
	margin-left: 2%;
}


.utils_item_user_btn{
	background-color: #e2007a;
	border-color: #e2007a;
	width: 100%;
	margin-bottom: 7%;
	color: white;
	font-weight: bold;
	text-align: center;
}


.utils_item_user_btn:hover{
	background-color: white;
	color: #e2007a;
}


.bg_item_profil{
	background: white;
	padding: 1%;
	margin-bottom: 2%;
	border: 1px dashed #e2007a;
}


.bg_item_profil p {
	color : #e2007a;
}


#wrap_compte_user_edit{
/* 	background-color: #e2e4e5; */
	padding: 2%;
	display: block;
/* 	float: left; */
	margin: 0 auto;
	width: 58%;
	margin-top: 4%;
}



.utils_profil_edit{
	background-color: white;
/* 	width: 20%; */
/* 	float: left; */
	height: auto;
	padding: 1%;
	margin-bottom: 2%;
	 border: 1px dashed #e2007a;
	
}



.utils_profil_edit p {
	color: #e2007a;
}



.utils_profil_edit tr{
	
}



.utils_profil_edit .form-control{
	margin-bottom: 10px;
	background-color: #e2e4e5;
	border: none;
	border-radius: 0;
}


.edit_user_btn_continue{
	padding: 8px;
    border: 0;
    background-color: #e2007a;
    transition: all .4s ease;
    font-weight: 700;
    color: #fff;
    margin-top:0; 
    float: right;
}

.edit_user_btn_retour{
	padding: 8px;
    border: 0;
    background-color: #e2007a;
    transition: all .4s ease;
    font-weight: 700;
    color: #fff;
    margin-top:1%; 
/*     float: left; */
    float: none;
}

.edit_user_btn_retour:hover{
  opacity: .5;
}



.edit_user_btn_continue:hover {
    opacity: .5;
}



#wrap_compte_user_update{
/* 	background-color: #e2e4e5; */
	padding: 2%;
	display: block;
/* 	float: left; */
	margin: 0 auto;
	width: 58%;
	margin-top: 1%;
	border: 1px dashed #e2007a;
	margin-bottom: 2%;
	padding-bottom: 1%;
}

#wrap_compte_mfa{
/* 	background-color: #e2e4e5; */
	padding: 2%;
	display: block;
/* 	float: left; */
	margin: 0 auto;
	width: 58%;
	margin-top: 4%;
	border: 1px dashed #e2007a;
	
}

.bg_item_profil_update{
	background: white;
	padding: 1%;
	margin-bottom: 2%;
	
}

.compte_user_update_title{
	  color: #e2007a;
}


.next_profil_compte{
padding: 8px;
border: 0;
background-color: #e2007a;
transition: all .4s ease;
font-weight: 700;
color: #fff;
margin-top: 1%;
float: right;
margin-top: 0%;
}

.next_profil_compte:hover {
    opacity: .5;
    color: #fff;
}

.form-example{
	text-align: right;
	float: right;
}

.btn_ajouter{
	padding: 8px;
	border: 0;
	background-color: #e2007a;
	transition: all .4s ease;
	font-weight: 700;
	color: #fff;
	font-size: 14px;
 	margin-top: 50px;
}

.btn_ajouter:hover{
    opacity: .5;
    color:white;
}

.modal-dialog-personalise{
	width: 60%;
    margin-top: 14%;
    text-align: center;
}

.wrap_btn_edit_profil{
	display: grid;
/* 	margin-top: 4%; */
	width: max-content;
	float: left;
}


#wrap_new_medecin_compte{
	padding: 2%;
	display: block;
/* 	float: left; */
	margin: 0 auto;
	width: auto;
	margin-top: 1%;
	border: 1px dashed #e2007a;
	margin-bottom: 2%;
	padding-bottom: 4%;
}


#wrap_new_medecin_compte .form-control{
	margin-bottom: 10px;
	background-color: #e2e4e5;
	border: none; 
	border-right : 1px solid #ccc;
	border-radius: 0;
}


#espace_for_fields > *{
	margin-right: 1%;
}


#wrap_new_medecin_compte label{
	margin-right: 7px;
}



.title_medecin_new{
	color:#e2007a;
}


#update_mdp_h3{
	color: #e2007a; 
	padding-left: 21%;
}

.wrapheader{
	margin-bottom:70px;
}
/*.navbar{*/
/*	background-color: #75b70b !important;*/
/*	box-shadow: 2px 2px 2px #444;*/
/*	padding-top: 0px !important;*/
/*	padding-bottom: 0px !important;*/
/*	margin-bottom: 1%;*/
/*	height: 150px;*/
/*}*/

.little_level > .bi-star-fill{
	color: white;
	color: white;
	display: block;
	float: left;
	font-size: 2em;
	margin-left: 6px;
	margin-top: -1px;
}


.accordion-button{
	font-weight: bold;
	color: #318ce7;
}

#showEvenement > fieldset:after{
	content: "";
	color: red;
}

.contain_nav_nutrinet{
	box-shadow: 1px 1px 8px !important;
}

.notice_new_article{
	display: block;
	width: 50%;
	background: #f0ebeb;
	padding: 5px;
	float: left;
}

.error_article_img{
	color: red;
	display: block;
	width: 50%;
	height: 20px;
}

.btn-type-nutrinet{
	cursor: pointer;
	font-weight: bold;
	background: #e2007a !important;
	border: 1px solid #e2007a !important;
	color: white !important;
	padding: 6px;
	margin-top: 10px;
	display: inline-block;
	width: max-content;
	margin-bottom: 10px;
	text-align: center;
	box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	-webkit-box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	-moz-box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	margin-left: 3px;
}

.gris {
	background-color: #eeee;
}

.btn-type-nutrinet-gris{

	font-weight: bold;
	background: #eeee !important;
	border: 1px solid #eeee!important;
	color: white !important;
	padding: 6px;
	margin-top: 10px;
	display: inline-block;
	width: max-content;
	margin-bottom: 10px;
	text-align: center;
	box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	-webkit-box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	-moz-box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	margin-left: 3px;
}


.btn-type-nutrinet:hover{
	color: #e2007a !important;
	background: white !important;
	border-color: #e2007a !important;
}


.btn-type-nutrinet-left{
	cursor: pointer;
	font-weight: bold;
	float: left;
	background: #e2007a !important;
	border: 1px solid #e2007a !important;
	color: white !important;
	padding: 6px;
	margin-top: 1%;
	margin-bottom: 1%;
	display: block;
	width: max-content;
	text-align: center;
	box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	-webkit-box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	-moz-box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	margin-left: 3px;
}


.btn-type-nutrinet-left:hover{
	color: #e2007a !important;
	background: white !important;
	border-color: #e2007a !important;
}

.btn-type-nutrinet-right{
	cursor: pointer;
	font-weight: bold;
	float: right;
	background: #e2007a !important;
	border: 1px solid #e2007a !important;
	color: white !important;
	padding: 6px;
	margin-bottom: 1%;
	margin-top: 1%;
	display: block;
	width: max-content;
	text-align: center;
	box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	-webkit-box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	-moz-box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
	margin-left: 3px;
}

.btn-type-nutrinet-right:hover{
	color: #e2007a !important;
	background: white !important;
	border-color: #e2007a !important;
}

.list-inline > li > .btn-type-nutrinet{
	float: left;
	margin-right: 1%;
}

#delete_form{
	display: flow-root;
}

.link_pointer{
	cursor: pointer !important;
}

.link_arrow{
	cursor: default !important;
}

.panel-nutrinet .ui-accordion-header-icon{
	color: #e2007a;
}

.right-icon-list-article-admin  .bi-card-list {

	color: #e2007a;
	font-size: 1.5em;
}

.wrap-btn-email-detals > div > .btn{
	background: #e2007a;
	border: 1px solid #e2007a;
	color: white;
	width: max-content;
}

.wrap-btn-email-detals > div > .btn:hover{
	color: #e2007a;
	background: white;
	border-color: #e2007a;
}

.form-group > .checkline > .radio-inline {
	margin-left: 10px !important;
	margin-top: 5px !important;
	font-weight: normal !important;
}

.form-group > .checkline >  .radio > label{
	margin-left: 10px !important;
	margin-top: 5px !important;
	font-weight: normal !important;
}

.form-group .radio > label{
	margin-left: 10px !important;
	margin-top: 5px !important;
	font-weight: normal !important;
}


.card_consentement_inscription form{
	display: block !important;

}

.table-logo-quest > .form-check-input{
	text-align: center;
}

.form-group{
	margin-bottom: 1% !important;
}
.utils_profil_edit table > tbody > tr > td > .addressnum , .voie , .afteraddressnum{
	margin-right: 1%;

}
/*.modal-backdrop {*/
/*	z-index: -1 !important;*/
/*}*/
@media (max-width: 991px) {
	.dropdown-menu .dropdown-menu{
		margin-left:0.7rem; margin-right:0.7rem; margin-bottom: .5rem;
	}
}