.politique_content h2 {
    text-align: center;
}

.politique_content p,.politique_content li {
    font-size:11pt;
    font-family:'Calibri',sans-serif
}

.politique_content p {
    font-size:11pt;
    font-family:'Calibri',sans-serif;
     text-align:justify
}
.politique_content p u{
    background-color:white;
    color:#212529;
}