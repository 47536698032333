
/*RESPONSIVE TABLETTE */
@media screen and (min-width: 768px) and (max-width: 1024px) {

    .nav-item-nutrinet:hover{
        background-color: transparent;
    }

    /*.navbar-collapse {*/
    /*    flex-basis: auto;*/
    /*    flex-grow: 1;*/
    /*}*/

    .wrap_content {
        display: none;
    }

    a.navbar-brand {
        margin-left: 0%;
    }


    .navbar-inverse {
        margin-bottom: 2%;
        height: 110px;
    }


    .logo {
        width: 4.5em;
        margin-top: -16px;
    }

    .navbar-brand img{
        width: 53%;
    }

    .navbar-toggler {
    border-color: white !important;
    }
    .navbar-toggler-icon{
        border-color: white !important;
    }

    .navbar-expand-lg .navbar-nav {
        height: auto !important;
    }

}

/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*////////////// TABLETTE PAYSAGE ////////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/

@media screen and (min-width:768px) and (max-width:1024px)and
(orientation:landscape) {



    .nav-link-nutrinet {
        padding-top: 35px;
        padding-bottom: 31px;
    }
    .nav-item-nutrinet {
        margin-left: 13px;
        font-size: 0.7em;
    }

    .nav-item > .nav-link > .bi::before {
        font-size: 18px;
    }

    .navbar-expand-lg .navbar-nav {
        height: 110px !important;
    }

}