.mr_btn_pre{
	margin-left : 15px;
}

select option[disabled]{
	background-color: #eaeaea;
}

.choix_date:hover{
color: #e2007a;
}


.archive_button{
	margin-left: 3%;
}	
	.table_quest {
    width: 94%;

    margin: auto;
}




.table_quest > thead > tr > th{
    padding: 0.8%;
    font-weight: 700;
    background-color: #e2007a;
    color: white;
    font-size: 20px;

}


.table_quest>tbody>tr:nth-of-type(odd){
	    background-color: #f9f9f9;
}

.table_quest .mes_quest:hover {
    background-color: #e9f5fa;
    cursor: pointer;
}

.table_quest > tbody > tr > td{
padding: 0;
border-top: 1px solid #ddd;
}


.table_quest > tbody > tr > td  a{
color: #414041;
font-size: 18px;
font-weight: normal;
}


.table_quest > tbody > tr > td > form button {
    color: #414041;
    font-size: 18px;
    font-weight: normal;
}

#content .btn-link {
    font-weight: normal;
    text-decoration: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: -12px;
    font-size: 18px;
	background: transparent;
	border: none;
	text-align: left;
}



/* .table_quest tr:nth-child(even) {background:#e2e4e5 ;} */
/* .table_quest tr:nth-child(odd) {background: #FFF;} */




/* .table_quest > tbody > .quest_alim > td{ */
/* background: #fbdced; */
/* 	} */

.table_quest > tbody > .quest_alim:nth-of-type(2n+1) {
    background-color:#fbdced;
}

.table_quest > tbody > .quest_alim:nth-of-type(2n+1):hover {
    background-color: #e9f5fa;
    cursor: pointer;
}

.encours_quest,input[type="submit"].encours_quest{
	color:#2f90d4 !important
}



.table_quest > tbody > tr > td > form .encours_quest{
	color:#2f90d4;
}

.mes_quest .choix_date input[type="submit"]{
	color: #414041 !important;
}




.table_quest > tbody > tr > td a:hover {
    color: #e2007a;
}



.table_quest > tbody > tr > td > form button:hover {
    color: #e2007a;

}

.table_quest > tbody > tr > td > form .encours_quest:hover{
	color:#2f90d4;
}


.table_quest > tbody > tr > td  > a{
	display: inline-block;
    width: 100%;
    height: 100%;
    padding: 14px;
}


.table_quest > tbody > .mes_quest > td{
	padding: 14px;
	}

/* .table_quest > tbody > .quest_alim > td  > form{ */
/* /* margin-left: 6%; */ 
/* } */






.table_mes_quest .quest_alim{
 	 background:#fbdced;
}


.table_mes_quest .quest_alim:hover{
  cursor: pointer;
  background:#e9f5fa;
}


.choix_date{
	color: #414041;
	font-size: 18px;
	font-weight: normal;
	cursor: pointer;
	}



.quest_archives .link_archive{
	display:inline-block;
    width: 100%;
    height: 100%;
    padding: 14px;
    color: #414041;
	font-size: 18px;
}	



.tr_archives > td {
	padding: 0;
	}
	
	
	.tr_archives:hover{
	background-color: #e9f5fa;
}




.table-striped > tbody > .tr_archives:nth-of-type(2n+1):hover {
	background-color: #e9f5fa;
}



.preremplir{
width: max-content;
background-color: #e2007a;
margin: 0 auto;
color: white;
padding: 10px;
margin-top: 40px;
font-weight: bold;
border: 1px solid #e2007a;
display: block;
}


.preremplir:hover{
	cursor: pointer;
	background-color: white;
	font-weight: bold;
	color: #e2007a;
	
}

/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*///////////////TABLETTE PORTRAIT/////////*/
/*/////////////////////////////////////////*/





/*RESPONSIVE TABLETTE */
@media screen and (min-width: 768px) and (max-width: 1024px) {

	
.choix_date{
	font-size: 14px;
}	
	
.table_quest > tbody > tr > td  a{
	font-size: 14px;

}


.table_quest > tbody > tr > td > form button {
	font-size: 14px;
}

#content .btn-link {
	font-size: 14px;
}


.table_quest > thead > tr > th {
 	font-size: 17px;
}

}




/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*///////// SMARTPHONE PORTRAIT //////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
@media screen and (max-width: 767px) {

	.col-td-quest-nom{
		background-color: yellow;
		width: 268%;
	}

	.col-td-quest-status{
		background-color: yellow;
		width: 110%;
	}

	.col-td-quest-dispo{
		background-color: yellow;
		width:80%
	}
	
.choix_date form input{
	font-size: 13px;
	white-space: initial;
	text-align: left;
}	

	
.choix_date form{
	font-size: 13px;
}		
.table_quest > tbody > tr > td  a{
	font-size: 13px;

}

.table_quest > tbody > tr > td > form button {
	font-size: 13px;
}

#content .btn-link {
	font-size: 13px;
}


.table_quest > thead > tr > th {
	 font-size: 13px;
	 padding: 4%;
}
	
	
	.table_quest {
/*     width: 94%; */
    margin: 0;
    display: block;
    width: auto;
}

#tdbody_groupe2{
	   display: block;
}

.table_quest thead{
	display: block;
	width: auto;	
}

.quest_th{
    width: 100%;
    display: flex;
}

/* .quest_th th { */
/* font-size: 13px; */
/* } */

#tdbody_groupe2 .mes_quest{
	/*display: flex;*/
	width: 100%;
}

/* #tdbody_groupe2 .choix_date{ */
/* 	display: flex; */
/* 	width: 100%; */
/* } */

.quest_alim td .btn{
	display: inline-block;
	padding: 6px 12px;
	white-space: initial;
	text-align: left;
}

.last_th_quest{
	width: 27%;
}
	
.quest_archives .link_archive {
	padding: 6px;
	font-size: 11px;
}
	

	
}







/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*//////// SMARTPHONE PAYSAGE /////////////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/

/*RESPONSIVE SMARTPHONE PAYSAGE*/
@media screen and (max-width :767px)and (orientation: landscape) {
.table_quest > thead > tr > th {
	padding: 2%;
	}
}






















