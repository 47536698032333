/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*///////////////TABLETTE PORTRAIT/////////*/
/*/////////////////////////////////////////*/
/*////////////////LES ACTIVITES////////////*/




/*RESPONSIVE TABLETTE */
@media screen and (min-width: 768px) and (max-width: 1024px) {


	.inscri_connect_index_connect .wrap_btn_index i {
		font-size: 20px;

	}


	.inscri_connect_index_connect {
		font-size: 14px;
		margin-top: 140px;
	}

	.wrap_buttons a {
		font-size: 0.9em;
	}

	.wrap_bas_article{
		margin-top: 3%;
		border: 1px solid #f2f2f2;
		padding-bottom: 0;
		padding-top: 1%;
		display: inline-block;
		height: initial;
	}
	.selectline_small2{
		width: 100%;
	}

	.question_compalim{
		width: auto !important;
	}

	.password_eye, .password_eye2{
		right: -266px;
		cursor: pointer;
		top: -32px;
	}
	.bg_item_profil_update .form-control{
		width: 100%;
	}

	.selectline_small{
		width: 89%;
	}

	.edit_art_one {
		width: 100%;
		font-size: 1em;
	}

	.partenaire img {
		width: 70%;
	}


	.title_bilan_q {
		width: 65%;
		font-size: 0.9em;
	}

	.wrap_user {
		margin-top: -15px;
	}

	/*.navbar-collapse {*/
	/*	flex-basis: auto;*/
	/*	flex-grow: 1;*/
	/*}*/

	.wrap_content {
		display: none;
	}

	/*a.navbar-brand {*/
	/*	margin-left: 3%;*/
	/*}*/


	/*.navbar-inverse {*/
	/*	margin-bottom: 2%;*/
	/*	height: 110px;*/


	.logo {
		width: 4.5em;
		margin-top: -16px;
	}



	.content_tirage_popup{
	width: 95%;
	}
	
	#new_iframe{
	height: 200px;

}
	
.wrap_face2 {
	margin-left: 22%;
}
	
.entete_afficher_masquer{
	margin-left: 7%;
    width: 4%;
    margin-right: 0%;
}	
	
.entete_edit{
	margin-left: 17%;
}	

.entete_left_faq, .faq_quest {
    float: left;
    width: 80%;
}	
	
.right_marget:first-child{
	margin-right: 9%;
}	

.right_marget:last-child{
	margin-right: 0%;
}	
	
	
.entete_left_faq {
    float: left;
    width: 62%;
}
	
	
	.wrap_form_render input[type="date"]{
	width: 30%;
}		
	
.wrap_form_render input[type="number"]{
	width: 136px;
}	
	
		.facebook1 iframe{
	width:169px;
	}
	
	
	.face2facebook {
    width: 0;

	margin-left: 56%;
}
	
	
.wrap_h_old{
	top: 68px;
	
}
	.sec {
    font-size: 2em;
}
	
	.flash-noticeInscr {
	padding: 0.5em;
	font-size: 14px;
	top: 185px;
    left: 5%;
    right: 5%;
}


	.flash-warning {
	padding: 0.5em;
	font-size: 14px;
	top: 185px;
    left: 5%;
    right: 5%;
}


	.flash-error {
	padding: 0.5em;
	font-size: 14px;
	top: 185px;
    left: 5%;
    right: 5%;
}


	.flash-notice {
	padding: 0.5em;
	font-size: 14px;
	top: 185px;
    left: 5%;
    right: 5%;
}

	
	
.text-accroche{
	font-size: 18px;
	margin: 0px;
}


.title_nutrinet {
    margin-bottom: 60px;
	padding: 6px;
	font-size: 25px;
}	
	
	
.title_news1{
padding: 5px 10px;
font-size: 15px;
}


.article{
margin-bottom: 100px;

padding-bottom: 15px;
padding-left: 8px;
padding-right: 8px;
width: 100%;

}

.article .body p{
font-size: 13px;
}


.cons_view_news a{
	width: 100%;

}	
	
	

#twitter_unique2 {
    margin-top: 10px;
    margin-left: 73%;
    float: left;
}


	#label_switch {
		margin-left: 45%;
	}
	.modal-content1 {
		margin-top: 4%;
		width: 85%;
	}

	/* 	#texte_label { */
	/*    float: none; */
	/* } */
	.wrap_hide {
		top: 167px;
		width: 62%;
	}
	.little_level {
		width: 28%;
		height: 42px;
	}
	.donne_p {
		margin-bottom: 58px;
	}
	.result_wrap img {
		width: 80%;
	}
	.only_connect {
		height: 150px;
		/*margin-top: -16px;*/
	}
	.donne_profil {
		float: left;
		padding: 2%;
		margin-left: 2%;
		border-right: 1px solid #8c7b7b66;
		margin-top: 2%;
		height: 40%;
		width: 17%;
	}
	.donne_t {
		font-size: 15px;
		height: 44%;
	}
	.donne_p {
		font-size: 12px;
	}
	.item_hide {
		width: 100%;
		border-bottom: 1px solid #bf8970;
		float: left;
		padding: 2%;
	}
	.sep_lit_livelb span {
		margin-right: 32%;
	}
	.hide_respons {
		position: absolute;
		top: 213px;
		width: 60%;
		height: auto;
		background-color: #e2e6dc;
		font-size: 1em;
		z-index: 10;
	}
	.sep_lit_livelb {
		border-width: 1px;
		border-left-width: 1px;
		width: 24%;
		float: right;
		height: 100%;
		line-height: 3em;
		border-left: 1px dashed white;
	}
	.little_level_nameb {
		color: white;
		font-weight: bold;
		float: left;
		margin-top: 6%;
		font-size: 1em;
	}
	.little_levelb > .bi-star-fill {
		font-size: 3em;
		line-height: 0.9em;
		color: #fff;
		font-size: 2.3em;
		margin-top: 2%;
		float: left;
		margin-left: 3%;
	}
	.little_donneb {
		position: absolute;
		left: 18px;
		top: 14px;
		font-size: 11px;
		font-weight: bold;
		z-index: 2;
		/* color: #bf8970; */
	}
	.little_levelb {
		position: absolute;
		top: 154px;
		width: 23%;
		height: 42px;
		background-color: #bf8970;
		float: left;
		display: block;
		left: 0;
	}
	.wrap_profil1 {
		display: none;
	}
	#sec2 {
		height: 269px;
		margin-bottom: 94px;
		margin-top: 64px;
	}
	.wrap_accroche {
		left: 27%;
		width: 45%;
		font-size: .9em;
		top: 17%;
		height: 8%;
		font-weight: bold;
	}
	#actualites {
/* 		height: 700px; */
	}
	.seperateur img {
		width: 30%;
	}
	.seperateur {
		width: 45%;
		height: 35px;
		margin: 0% 26% 1% 30%;
	}
	.sqare {
		width: 20px;
		height: 20px;
	}
	.seperateur .firsthr, .lasthr {
		position: relative;
		float: left;
		width: 30%;
		height: 20px;
		margin-left: 5%;
		margin-right: 5%;
		margin-top: -10px;
	}
	.sec4 .sec {
		font-size: 1.5em;
		margin-bottom: 20px;
		margin-left: 0%;
		border: 1px solid #E2007A;
		width: 49%;
		margin-right: 1%;
		background-color: #fbe6f3;
		margin: 0 auto;
	}
	.title_news {
		font-size: 0.7em;
	}
	#actualites article .body_article {
		width: 100%;
		min-height: auto;
	}
	#actualites article {
		margin-left: 2%;
		border: 1px solid #E2007A;
	}

	/* #actualites article { */
	/* margin-left: 1%; */
	/* border: 1px solid #E2007A; */
	/* width: 98%; */
	/* margin-bottom: 3%; */
	/* } */
	#plusarticles {
		border: 1px solid #e2007a;
		height: 35px;
		width: 20%;
		text-align: center;
/* 		margin: 0 auto; */
		font-size: 1em;
		padding: 7px;
		margin-bottom: 78px;
		margin-top: 50px;
		margin-left: 40%;
	}
	#ftr img {
		width: 13%;
	}
	.part img {
		width: 80%;
		margin-left: -3%;
	}
	#sec4 {
/* 		margin-bottom: 100px; */
		text-align: center;
		margin-top: 10%;
	}
	#ftr article {
		margin-left: 11%;
		width: 20%;
		font-size: 0.7em;
		border-right: none;
	}
	#ftr article h2 {
		font-size: 1.5em;
	}
	#ftr article a {
		line-height: 26px;
	}
	#actualites article .body_article p iframe {
		width: 100%;
	}

	.cone_inscrip_non_inscris{
		height: auto;
		margin-top: 26%;
		padding: 2px;
		width: 100%;
		margin-bottom: 7%;
	}

	/*A METTRE DANS LE CODE PROD*/
	.cone_inscrip {
		height: auto;
		margin-top: 27%;
		padding: 2px;
		width: 100%;
		margin-bottom: 7%;
	}

	.bienvenue {
		padding: 3px;
		font-size: 1em;
	}

	.rslides {
		top: 2%;
	}
	#header {
		line-height: 1.5;
		margin-top: 2px;
		font-size: 0.8em;
		height: 27px;
	}
	.bienvenue_user {
		font-size: 15px;
		padding: 0px 5px 0px 5px;
		font-weight: bold;
	}
	.connect_user span {
		font-size: 13px;
	}
	.crumb li {
		padding: 2px;
	}
	.menubouton>li>a {
		padding-bottom: 10px;
		height: 112px;
	}
	.col_new a {
		font-size: 0.8em;
		line-height: 3em;
	}
	.btn-inscription {
		min-height: 32px;
		padding: 1px;
	}
	.col_new {
		width: 36%;
		margin-right: -3%;
		font-size: 0.8em;
	}
	.init_pass {
		margin-left: 31%;
		width: 41%;
	}
	.init_pass a {
		font-size: 11px;
	}
	.alertadmin2 {
		padding: 4px;
		margin-top: -6%;
		width: 97%;
		left: 2.5%;
		font-size: 11px;
	}
	.closebtnadmin {
		margin-left: 15px;
		font-size: 21px;
		line-height: 13px;
	}
	#sec2 article {
		height: 73%;
		padding-top: 30px;
	}
	#sec2 article img {
		width: 50px;
		height: 50px;
	}
	.number_user {
		font-size: 0.7em;
		height: 30%;
		width: 42%;
		line-height: 56px;
	}

	#sec2 article blockquote {
		padding: 25px 15px;
		margin: 0 0 20px;
		font-size: 0.8em;
		border-left: none;
	}
	#actualites {
		width: 100%;
/* 		min-height: 900px; */
		margin-top: 5%;
		margin-bottom: 10%;
	}
	
	.publication_article{
	margin-bottom: 6%;
	}
	
	.profil_item {
    width: 64%;
}


.utils_profil {
	width: 33%;
	margin-right: 1%;
	font-size: 13px;

}

#wrap_compte_user_edit {
	width: 90%;
}

#wrap_compte_user_update {
	width: 90%;
}

#wrap_new_medecin_compte {
	width: 90%;
	padding-bottom: 9%;
}


#wrap_compte_mfa {
	width: 88%;
}

	.partenaire img{
width: 30%;
	}

	.bilan_only{
		right: 57px;
		top: 8px;
		font-size: 0.6em;
	}

	.box{
		width: 480px;
		padding: 12px;
		font-size: 1.5em;
		margin-top: -90px;
	}

	.vertical_line_accroche_right {
		visibility: visible;
		left: 192px !important;
	}

	.vertical_line_accroche_left {
		visibility: visible;
		left: -192px !important;
	}

	.nutrinet_btn_index {
		width: 82%;
		margin-top: 46px;

	}

	.inscri_connect_index{
		font-size: 18px;
		margin-bottom: 14px;
	}

	.inscri_connect_index .wrap_btn_index .lien_inde_btn{
		line-height: inherit;
		height: 40px;
	}

	.inscri_connect_index .wrap_btn_index i{
		height: 40px;
		line-height: 33px;
		font-size: 18px;
	}

	.reinit_index .lien_inde_btn {
		padding: 0%;
		border: 1px solid #75b70b;
		font-size: 15px;
		width: 85%;
		height: 40px;
		line-height: 35px;
	}

	.reinit_index	.wrap_btn_index_reinit{
		width: 66%;
		margin: 0 auto 0 18%;
	}

	.reinit_index .wrap_btn_index_reinit i {
		padding: 0%;
		border: 1px solid #75b70b;
		font-size: 21px;
		height: 40px;
		line-height: 34px;
	}

	.reinit_index_connect .wrap_btn_index_reinit i{
		font-weight: bold !important;
		padding: 2px;
		width: 12%;
		border-left: none;
		font-size: 20px;
		line-height: 2;
	}





}

/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*////////////// TABLETTE PAYSAGE ////////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/

@media screen and (min-width:768px) and (max-width:1024px) and (orientation:landscape) {




	.nutrinet_btn_index {
		margin-top: -46px;
	}


	.utils_profil {
		width: 33%;
		margin-right: 1%;
		font-size: 13px;

	}

	#sec4 .part {
		width: 10%;
	}

	#sec4 .part img{
		width: 97%;
		margin-left: -43px;
	}

	.cone_inscrip_non_inscris {
		margin-top: 27%;
	}

	.only_connect{
		/*margin-top: -16px;*/
	}

#new_iframe {
    height: 268px;
}
	
/*.modal-content {*/
/*max-height: max-content;*/
/*}	*/
	


.blog {
max-height: 27em;
}

.flash-noticeInscr {
    padding: 1em;
    font-size: 15px;
    top: 306px;
}


.flash-notice {
    padding: 1em;
   	font-size: 15px;
    top: 306px;
}



.flash-warning {
    padding: 1em;
    font-size: 15px;
    top: 306px;
}

.flash-error {
    padding: 1em;
    font-size: 15px;
    top: 306px;
}
	
	
	
.face2facebook {
margin-left: 61%;
/*     float: left; */
/*     margin-left: 23%; */
}	
	
#twitter_unique2 {
    margin-top: 9px;
    margin-left: 75%;
    float: left;
}
	
	
	.little_levelb .bi-star-fill {
		font-size: 2em;
		line-height: 0.8em;
	}
	.wrap_hide {
		top: 184px;
		width: 53%;
		height: auto
	}
	.little_level {
		width: 22%;
		height: 42px;
	}
	.little_level_nameb {
		margin-top: 5%;
	}
	.little_donneb {
		top: 15px;
		left: 15px;
	}
	.sep_lit_livelb {
		width: 21%;
	}
	.hide_respons {
		width: 50%;
		top: 242px;
	}
	.result_wrap img {
		width: 63%;
	}
	.little_levelb {
		top: 200px;
	}
	.wrap_accroche {
		top: 226px;
		height: 10%;
		font-weight: bold;
		font-size: 1em;
	}
	#actualites {
/* 		height: 732px; */
	}
	#ftr article {
		margin-left: 12%;
		width: 19%;
		font-size: 0.8em;
	}
	.seperateur {
		margin: 0% 26% 1% 30%;
	}
	#btval {
		width: 11%;
		font-size: 15px;
		padding: 1px;
	}
	#btl {
		width: 20%;
		font-size: 14px;
		padding: 4px;
	}
/* 	#actualites { */
/* 		min-height: 900px; */
/* 	} */
	.col_new {
		width: 35%;
		margin-right: -2%;
	}
	.alertadmin2 {
		margin-top: -5%;
		width: 97.7%;
		left: 1.5%;
	}

	.cone_inscrip {
		margin-top: 266px;
	}


	.box{
		width: 480px;
		padding: 12px;
		font-size: 1.5em;
		margin-top: -90px;
	}

	.vertical_line_accroche_right {
		visibility: visible;
		left: 192px !important;
	}

	.vertical_line_accroche_left {
		visibility: visible;
		left: -192px !important;
	}

	.nutrinet_btn_index {
		width: 82%;
		margin-top: 46px;

	}

	.inscri_connect_index{
		font-size: 18px;
		margin-bottom: 14px;
	}
	.inscri_connect_index .wrap_btn_index .lien_inde_btn
{
		line-height: inherit;
		height: 40px;
	}

	.inscri_connect_index .wrap_btn_index i{
		height: 40px;
		line-height: 33px;
		font-size: 18px;
	}

	.reinit_index .lien_inde_btn {
		padding: 0%;
		border: 1px solid #75b70b;
		font-size: 15px;
		width: 85%;
		height: 40px;
		line-height: 35px;
	}

	.wrap_btn_index_reinit{
		width: 60%;
	}

	.reinit_index .wrap_btn_index_reinit i {
		padding: 0%;
		border: 1px solid #75b70b;
		font-size: 21px;
		height: 40px;
		line-height: 34px;
	}
}






/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
/*////// ECRAN ENTRE  766px ET 840px ///////*/
/*//////////////////////////////////////////*/
/*//////////////////////////////////////////*/
@media screen and (min-width: 766px) and (max-width: 840px) {
	.dropdown-menu-right {
		/*   //top: 100%;   */
		/*top: 100%;*/
		/*   //right:-14.5em; */
		right: auto;
		left: 100%;
		top: 0%;
	}
	.btn-inscription, .btn-number {
		font-size: 7px;
	}
	.logo {
		width: 6.5em;
	}
	.menubouton {
		font-size: .7em;
		margin-left: 1%;
	}
	.menubouton li {
		font-size: .7em;
	}
	.menubouton li li {
		font-size: .7em;
	}
	.menu_log li li {
		font-size: .7em;
	}
	.menu_log {
		font-size: .8em;
		position: absolute;
	}
	a font .badge {
		font-size: 0.9em;
	}

	/*Texte cohorte nutrinet*/
	.test font {
		font-size: 0.7em;
	}
	.marge {
		width: 0px;
		height: 0px;
		visibility: hidden;
	}
	.menu_head {
		max-height: 70px;
	}
	.hidden-1130 {
		display: none !important;
		visibility: hidden;
		width: 0px;
	}
	#bar {
		visibility: hidden;
	}
}













