.label_render img, .texte_seul img, .article .body img{
	max-width: 100%;
	height: auto;
	width: initial;
}
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*///////// SMARTPHONE PORTRAIT //////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/


@media screen and (max-width: 767px) {

	.password_eyebis, .password_eye2bis{
		right: -133px;
		cursor: pointer;
		top: -22px;

	}

	#questionnaire{
		overflow-x: scroll;
		display: block;
	}

	.nutrinet_btn_index {
		width: 100%;
		margin: 0 auto;
		margin-top: 70px;
	}

	.wrap_btn_index {
		width: 100%;
		float: left;
		margin: 0 0 20px 0;
	}


.reinit_index_connect .wrap_btn_index_reinit {
		margin: 0 auto;
		width: 100%;
	}

	.reinit_index .wrap_btn_index_reinit {
		margin: 0 auto;
		width: 100%;
	}



	.reinit_index .lien_inde_btn {
		color: white;
		font-weight: bold;
		font-size: 16px;
		width: 90%;
		float: left;
	}

	.reinit_index_connect .lien_inde_btn {
		color: white;
		font-weight: bold;
		font-size: 16px;
		width: 90%;
		float: left;
	}

	.inscri_connect_index {
		margin-bottom: 0px;
	}

	.inscri_connect_index_connect {
		margin-bottom: 0px;
		margin-top: 80px;
		font-size: 18px;

	}

	.inscri_connect_index .wrap_btn_index .lien_inde_btn{
		width: 89%;
	}
	.inscri_connect_index .wrap_btn_index i {
		padding: 3px;
		width: 11%;
		border-left: none;
		margin: 0;
		font-size: 18px;

	}

	.inscri_connect_index_connect .wrap_btn_index .lien_inde_btn{
		width: 89%;
	}
	.inscri_connect_index_connect .wrap_btn_index i {
		padding: 3px;
		width: 11%;
		border-left: none;
		margin: 0;
		font-size: 18px;

	}




.reinit_index .wrap_btn_index_reinit i {
	font-weight: bold !important;
	padding: 2px;
	width: 10%;
	border-left: none;
	font-size: 20px;
	line-height: 2;

}

	.reinit_index_connect .wrap_btn_index_reinit i{
		font-weight: bold !important;
		padding: 2px;
		width: 10%;
		border-left: none;
		font-size: 20px;
		line-height: 2;
	}

	#menuid{
		width: 100%;
	}

	#wrap_new_medecin_compte label{
	font-size: 11px;
	}

	/*.inscri-card*/

	/*.navbar-toggler-icon{*/

	/*}*/

	.navbar-light .navbar-toggler{
		border-color: white !important;
	}

	#btval {
		width: 100%;
		font-size: 18px;
		padding: 5px;
		margin-bottom: 2%;
		margin-right: 0%;


	}


	#wrap_im {
		margin-bottom: 1%;
		width: max-content;
	}

	.notice_new_article {
		width: 100%;
		margin-top: 5%;
	}

	.text_copy {
		font-size: 0.8em;
	}


	.wrap_bas_article{
		margin-top: 3%;
		border:none;
		margin-right: 4%;
	}

	#wrap_msg_error h1{
	margin-top: 5%;
	font-size: 3em;
	color: #e2007a;
	}

	#wrap_msg_error h2{
	font-size: 1.5em;
	}
	
	.modal-dialog-personalise{
	width: 90%;
    margin-top: 14%;
    text-align: center;
}
	
	.tr_bio th,td{
	font-size: 0.7em;
	}
	
	#new_iframe{
	height: 200px;

}

	.publication_article{
	margin-bottom: 13%;
	}
	
.entete_afficher_masquer{
    margin-left: -20%;

}	
	
.entete_edit{
	margin-left: 10%;
}	
	
	
.entete_faq {
	padding-top: 4px;
    padding-bottom: 4px;
    height: 25px;
    font-size: 10px;
}	
	
.entete_left_faq, .faq_quest {
	width: 77%;

}	

.entete_left_faq{
    width: 65%;
}
	
.answer_faq{
	font-size: 9px;
	padding: 4px;
}	

	
.wrap_form_render input[type="date"]{
width: 100%;
}	
	
.wrap_form_render input[type="number"]{
	width: 85px;
}	
	
	.bleu-nutrinet1{
	width:100%;
	}
	
	.bleu-nutrinet{
	width:100%;
	}
	

/* 	.face2facebook iframe{ */
/* 	width:168px; */
/* 	} */
	
/* 		.facebook1{ */
/* margin-right: 87%; */
/* 	} */
	
	.facebook1 iframe{
	width:169px;
	}
	
	#ftr {

    margin-top: 200px;
}
	
	.wrap_h_old {
    top: 18px;

}
.libMax.question_echelle {
font-size: 14px;
}

.edit_art_one {
width: 40%;
 font-size: 0.8em;
}

.article iframe {
	width: 98%;
}

.article body img {
	width: 100% !important;
}	



	
.activetotp{
	position: absolute;
    top: 1px !important;
}
	
.carousel-inner img{
	width: 100px;
	height: 80px;
}	
	
	
.inscri_be{
	font-size: 30px;
}	
	
	
	
.flash-noticeInscr {
padding: 0.5em;
font-size: 13px;
top: 106px;
left: 10%;
right: 10%;
}


.flash-notice {
padding: 0.5em;
font-size: 13px;
top: 106px;
left: 10%;
right: 10%;
}


.flash-warning {
padding: 0.5em;
font-size: 13px;
top: 106px;
left: 10%;
right: 10%;
}


.flash-error {
padding: 0.5em;
font-size: 13px;
top: 106px;
left: 10%;
right: 10%;
}
	

#new_consentement .modal-dialog .modal-content .modal-body .blog {
    height: calc(60vh - 30vh);
    overflow-y: scroll;
    background-color: #f0f0f0;
}
.modal-footer {
    padding: 0;
}
.h2-zone {
    font-size: 16px;
}
.title_nutrinet {
    margin-bottom: 40px;
    padding: 5px;
    font-size: 14px;
}	
	
	
#no_news{
    font-size: 17px;
    color: rgb(226, 0, 122);
    text-align: center;
    margin-top: -65px;
}	
	
	
#dynamic{
margin-top: -65px;
width: 12%;
}
	
	.title_news1{
padding: 5px 10px;
font-size: 15px;
}


.article{
margin-bottom: 70px;
padding-bottom: 15px;
padding-left: 8px;
padding-right: 8px;
width: 100%;
margin-left: 0%;
margin-right: 0%;
}

.article .body p{
font-size: 13px;
}


.cons_view_news a{
	width: 100%;
	padding: 5px;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
}
	
	
.result_wrap	.tac_activate {
	font-size: 10px;
  	width: 28%;
  	line-height: 12px;
}






	
.button_copy {
	float: inherit;
}
	
	.result_wrap .tac_activate {
    background: #333;
    width: 0;
    font-size: 12px;
}
	
	.face2facebook{
	margin-left: -25%;
	}
	
	#twitter_unique{
	margin-right: 6%;
	}
	
	#twitter_unique2 {
width: auto;
	}
	.legend_profil {
		font-size: 16px;
		margin-top: 7%;
	}
	#texte_label {
		float: none;
	}
	
	#label_switch::after {
		content: '';
		position: absolute;
		top: 3px;
		left: 5px;
		width: 15px;
		height: 15px;
		background: #fff;
		border-radius: 90px;
		transition: 0.3s;
	}
	
	#label_switch {
		cursor: pointer;
		text-indent: -9999px;
		width: 48px;
		height: 20px;
		background: grey;
		display: block;
		border-radius: 25px;
		position: relative;
		box-shadow: 2px 2px 3px black;
		margin-left: 2%;
	}
	#switch:checked ~ .wrap_profil .tr_profil .valeur_profil::after {
		content: '***********************';
		position: absolute;
		top: 32px;
		left: 68px;
		visibility: visible;
		font-size: 20px;
	}
	.tr_profil {
		height: 60px;
	}
	.wrap_buttons a {
		/*text-align: right;*/
		margin-left: 0%;
		display: block;
		text-align: center;
	}
	.wrap_buttons {
		text-align: left;
		margin-top: 2%;
	}
	.valeur_profil {
		width: 100%;
		padding: 5px;
		padding-left: 5px;
		padding-left: 3%;
		margin-left: 0%;
		text-align: center;
		line-height: 2em;
	}
	.modal-title1 {
		font-size: 15px;
	}
	.title_profil {
		float: left;
		width: 100%;
		background-color: #e2e6dc;
		padding-bottom: 2px;
		padding-top: 2px;
		text-align: center;
		color: #e2007a;
		font-weight: bold;
		border-right: none;
		padding-left: 0;
		padding-right: 0;
		height: 28px;
	}
	.wrap_profil1 {
		width: 100%;
		margin-top: 4%;
	}
	.modal-content1, .modal-content1 {
		margin-top: 3%;
		width: 310px !important;

	}
	#content {
padding: 0;
	}
	.grey_adresse {
		background-color: #ada5a5cc;
	}
	.wrap_face2 {
margin-bottom:0%;
margin-right: 0%;
margin-left: 39%;
	}

.face2twitter2{
	margin-right: 5%;
}
	.item_hide {
		padding: 2%;
	}
	.result_wrap img {
		box-shadow: none;
	}
	.navbar-header {
		box-shadow: 2px 2px 2px #715e5e;
	}
	#dt_left {
		margin-right: -3%;
	}
	/*.little_levelb 	.glyphicon-star {*/
	/*	font-size: 3.2em;*/
	/*	line-height: 0.8em;*/
	/*}*/
	/*.glyphicon-star {*/
	/*	font-size: 2.5em;*/
	/*	margin-top: 4%;*/
	/*}*/
	.hide_respons {
		position: absolute;
		top: 182px;
		width: 92%;
		height: auto;
		background-color: #e2e6dc;
		font-size: 0.8em;
		z-index: 200;
	}

	.wrapheader {
		margin-bottom: 30px;
	}
	.wrap_hide {
		position: absolute;
		top:164px;
		width: 92%;
		height: auto;
		background-color: #e2e6dc;
	}
	.sep_lit_livelb {
		border-width: 1px;
		width: 24%;
		float: right;
		height: 100%;
		line-height: 2.5em;
		border-left: 1px dashed white;
	}
	.sep_lit_livelb span {
		margin-right: 26%;
		font-size: 1.3em;
		line-height: 35px;
	}
	.little_level_nameb {
		color: white;
		font-weight: bold;
		float: left;
		margin-top: 4%;
		font-size: 1em;
		margin-left: 5px;
	}
	.little_levelb {
		position: absolute;
		top: 145px;
		width: 40%;
		height: 35px;
		/* background-color: #bf8970; */
		float: left;
		display: block;
		left: 0;
		z-index: 100;
	}
	.little_donne {
		position: absolute;
		left: 11px;
		top: 5px;
		font-size: 7px;
		font-weight: lighter;
	}
	.little_donneb {
		position: absolute;
		left: 15px;
		top: 14px;
		font-size: 8px;
		font-weight: bold;
		z-index: 2;
		/* color: #bf8970; */
	}
	.little_donne {
		position: absolute;
		left: 11px;
		top: 5px;
		font-size: 7px;
		font-weight: lighter;
		display: none;
	}
	.sep_lit_livel {
		border-width: 1px;
		width: 20%;
		padding-left: 0px;
	}
	.sep_lit_livel span {
		line-height: 22px;
		margin-left: 5px;
		font-size: 1.8em;
		line-height: 38px;

	}

	.little_levelb .bi-star-fill {
		color: white;
		font-size: 1.7em;
		line-height: .8em;
		float: left;
		margin-left: 5px;
		margin-top: 5px;
	}
	.little_level {
		position: relative;
		width: 40%;
		height: 35px;
		font-size: 0.7em;
		float: unset;
		margin-top: 10px;
	}
	.little_level_name {
		font-size: 1.5em;
		margin-left: 2%;
	}
	.donne_t {
		width: 19%;
		font-size: 14px;
		float: right;
		text-align: right;
		margin-left: 0;
		margin-right: 2%;
	}
	.donne_p {
		font-size: 14px;
		width: 75%;
		text-align: left;
		margin-bottom: 0px;
		float: left;
	}
	.donne_profil:first-child {
		margin-left: 0;
	}
	.only_connect {
		background-color: #e2e6dc;
		height: auto;
		margin-top: 182px;
	}
	.donne_profil {
		float: none;
		border-right: none;
		width: 100%;
		margin: 0 auto;
		padding: 3%;
		height: 42px;
		box-shadow: 0px 2px 2px #93848466;
	}
	.wrap_profil1 {
		display: none;
	}
	.bienvenue_user:first-child:hover {
		border: none;
	}

	.bienvenue_user:hover {
		border: none;
	}
	.connect_user {
		display: none;
		border: none;
	}
	#content {
		padding-left: 10px;
		padding-right: 10px;
	}
	.rslides {
		top: 54px;
		height: 100%;
		display: none;
	}
	.wrap_accroche {
		display: none;
	}
	.container {
		font-size: 10px;
		width: 100%;
	}
	html {
		overflow-x: hidden;
		width: 100%;
	}
	#header {
		display: none;
	}
	.connect_user span {
		font-size: 12px;
	}
	.bienvenue_user {
		font-size: 13px;
		padding: 3px;
		font-weight: bold;
		border: none;
		box-shadow: 1px 1px 1px black;
	}
	.menu_log {
		font-size: .8em;
		position: relative;
		height: 5px;
	}
	a font .badge {
		font-size: 0.9em;
	}
	.btn-inscription {
		white-space: normal;
	}

	/*Texte cohorte nutrinet*/
	.test font {
		font-size: 0.7em;
	}
	.marge {
		float: left;
		margin-top: 1%;
		width: 0px;
		visibility: hidden;
	}
	.menu_head {
		max-height: 70px;
	}
	.modif-rep {
		
	}
	a.btn {
		font-size: 12px;
	}
	textearea, input {
		font-size: 9px;
	}
	input[type="checkbox"], input[type="radio"], .radio-inline,
		.checkbox-inline {
		font-size: 8px;
	}
	.addressnum, .voie, .afteraddressnum {
		width: 33%;
	}
	.form-control {
		height: 25px;
		font-size: 9px;
	}
	/* 	.espace { */
	/* 		height: 5px; */
	/* 	} */
	table.table>input {
		/*width: 100%;*/
		height: 25px;
		font-size: 9px;
	}
	table>input, table>select {
		width: 100%;
		height: 25px;
		font-size: 9px;
	}
	.carousel-indicators {
		margin-bottom: -40px;
	}
	.carousel-indicators li {
		height: 17px;
		width: 17px;
	}
	.item-news-title>a>h4 {
		font-size: 1.3em;
	}
	.item-news {
		padding-bottom: 5px;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 5px;
	}
	.crumb {
		margin-top: 3em;
	}
	#bar {
		visibility: hidden;
	}
	.topbar {
		width: 100%;
	}

	/* For mobile phones: */
	.tuileHome {
		padding-top: 5px;
		padding-bottom: 5px;
		min-height: 120px;
		max-height: 120px;
		padding-left: 5px;
		padding-right: 5px;
	}
	.nutrinautes {
		font-size: 1.5em;
	}
	.tuileTexte {
		font-size: 0.9em;
	}
/* 	#actualites { */
/* 		min-height: 1200px; */
/* 	} */
	.sec {
		margin-bottom: 6%;
		font-weight: lighter;
		font-size: 1.5em;
	}
	#actualites article {
		width: 90%;
		border: 1px solid #E2007A;
		margin: 5% 5% 0 5%;
		-moz-box-shadow: 1px 1px 2px 0px #656565;
		-webkit-box-shadow: 1px 1px 2px 0px #656565;
		-o-box-shadow: 1px 1px 2px 0px #656565;
		box-shadow: 1px 1px 2px 0px #656565;
		filter: progid:DXImageTransform.Microsoft.Shadow(color=#343434,
			Direction=134, Strength=30);

	}
	.title_news {
		width: 100%;
		margin-bottom: 1%;
		float: none;
		margin-top: auto;
		min-height: auto;
		padding-top: 0;
	}
	#actualites article h1 {
		font-size: 1em;
	}
	#actualites article .body_article {
		width: 100%;
		min-height: auto;

	}
	.consulter a {
		font-size: 0.6em;
	}
	#ftr article {
		border-right: none;
	}
	.consulter {
		padding: 0% 2% 0% 2%;
	}
	#sec4 {
/* 		margin-bottom: 100px; */
		text-align: center;
	}
	#plusarticles {
		width: 100%;
		/* 		margin-top: 191%; */
		margin-bottom: 18%;
		font-size: 1.2em;
		padding: 5px;
		display: inline-block;
		margin-left: 0%;
		margin-right: 0%;
	}
	#sec4 .part {
		width: 13%;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		margin-left: 8%;
	}
	#sec4 .part img {
		width: 140%;
		margin-left: -22px;
	}

	/*////////////////////////////////*/
	/*////////////FLECHE ICONE////////*/
	/*////////////////////////////////*/
	.seperateur {
		width: 87%;
		height: 25px;
		margin: 23% 1% 1% 2%;
		padding-left: 20%;
	}
	.seperateur .firsthr, .lasthr {
		width: 29%;
		height: 20px;
		margin-left: 4%;
		margin-right: 4%;
		margin-top: -12px;
	}
	.seperateur hr {
		/* 	position: absolute; */
		/* 	top:2; */
		/* 	left:0; */
		border: none;
		border-bottom: 1px solid #e2007a; /* Ou border-top */
	}
	.sqare {
		width: 15px;
		height: 15px;
	}
	.sqare:hover {
		background-color: white;
	}

	/*/////////////////////////////////////////*/
	/*/////////// FOOTER RESPONSIVE ///////////*/
	/*/////////////////////////////////////////*/
	#ftr article {
		width: 88%;
		margin: 10% 6% 2% 6%;
		text-align: center;
		border-right: none;
		font-size: 0.6em;
		float: left;
	}
	#ftr article a {
		line-height: 21px;
	}
	#ftr article img {
		margin-right: 10px;
		display: none;
	}
	.menubouton {
		/* width: 100%; */
		text-align: left;
		margin-left: -14%;
		margin-top: 5%;
	}
	.menubouton li {
		text-align: center;
		height: 28%;
		margin-left: 0;
		text-align: left;
		font-size: small;
	}
	.glyph-nutrinet {
		font-size: 1.2em;
		line-height: 2em;
	}
	.wrap_content {
		display: none;
	}

	.partenaire {
		height: 110px;
	}
	.partenaire img{
		width: 40%;
	}

	.caption .partenaire {
		font-size: 0.8em;
	}

	/*A METTRE DANS LE CODE PROD*/
	.col_new {
		width: 100%;
		margin-left: 0%;
		margin-top: 2%;
		padding: 0;
	}
	.col_new a {
		font-size: 1em;
	}

	.cone_inscrip_non_inscris{
		margin-top: 21%;
		position: relative;
		background-color: white;
		height: 180px;
		padding: 1% 0 0 0;
		margin-left: 0;
		margin-right: 0;

	}
	.cone_inscrip {
		margin-top: 24%;
		position: relative;
		background-color: white;
		height: 180px;
		padding: 1% 0 0 0;
		margin-left: 0;
		margin-right: 0;
	}
	.init_pass a {
		font-size: 100px;
	}
	#content .btn {
		font-size: 13px;
	}
	.btn-inscription {
		min-height: 45px;
		padding: 9px;
	}
	.init_pass {
		margin-left: 0%;
		width: 100%;
	}
	.init_row {
		margin-top: 0%;
	}
	.alertadmin2 {
		width: 92%;
		left: 4%;
		font-size: 12px;
		margin-top: 5.3%;
	}
	.navbar {
		margin-bottom: 8px;
	}
	#sec2 {
		margin-bottom: 0;
		margin-top: 92px;
		height: 88px;
	}
	#sec2 article {
		width: 21%;
		margin: 3% 2%;
		float: left;
		/* 		border: 1px solid rgba(0, 0, 0, 0.1); */
		padding: 2%;
		height: 67px;
	}
	#sec2 article blockquote {
		margin-top: 8%;
		color: #444;
		font-size: 2em;
		display: none;
	}
	#sec2 article img {
		width: 40px;
		height: 40px;
	}
	#sec2 article:hover {
		color: #444;
		/* 		background-color: #d6eab7; */
	}
	.number_user {
		height: 77%;
		width: 92%;
		margin-left: 0%;
		font-size: 0.5em;
		line-height: 4em;
		margin-top: 10%;
	}
	.number_user p {
		line-height: 132%;
		padding-top: 18%;
	}
	.number_user p::after {
		content: "inscrits";
	}
	h1 {
		font-size: 55%;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
		color: white;
	}
	.theme_preprod .navbar-nav .open .dropdown-menu>li>a {
		color: white;
	}
	.theme_test .navbar-nav .open .dropdown-menu>li>a {
		color: white;
	}
	.menubouton li li {
		text-align: left;
		font-size: 15px;
		margin-left: 4%;
		font-weight: bold;
	}
	.menubouton li li:hover {
		background-color: #0000004d;
	}
	.icone_sec2 {
		margin-top: 8%;
	}
	
	.content_tirage_popup{
	width: 45%;
	}
	
.th_tr_archive th{
padding: 0.8%;
font-weight: 700;
background-color: #e2007a;
color: white;
font-size: 12px;
}

.tr_bio th{
	padding:4px !important;
}

.bienvenue {
	padding: 2px;
	font-size: 0.9em;
}


.profil_item {
    width: 64%;
}


.utils_profil {
	width: 100%;
    float: initial;
 	padding: 1%;
}


#wrap_compte_user {
    background-color: transparent;
    padding: 2%;
    display: block;
    float: left;
    margin: 0 auto;
	width: 100%;
    margin-top: 4%;
}





.profil_item {
    width: 100%;
    float: initial;
    margin-left: 0;
}


	#wrap_compte_user_edit {
	background-color: white;
	padding: 0;
    display: block;
/*  float: left; */
    margin: 0 auto;
		width: 100%;
   margin-top: 28%;
}

.utils_profil_edit {
	padding: 3%;
    margin-bottom: 7%;
   
}

/* #wrap_compte_user_update { */
/*     background-color: white; */

/* } */

.compte_user_update_title {
    font-size: 16px;
}

.bg_item_profil_update .form-control{
	width: 100%;
}


.password_eye, .password_eye2{
    right: -164px;
    cursor: pointer;
    top: -22px;
}

.change_mdp > table > tbody >tr  {
	vertical-align: baseline;
}

.selectline_small2 {
    width: 100%;
    margin-left: 2px;
}

.bg_item_profil_update {
	padding: 0%;
}

#wrap_compte_mfa {
	width: 100%;
}

#wrap_compte_user_update {
	width: 98%;
}

.bg_item_profil {
    background: white;
    padding: 2%;
    margin-bottom: 2%;
    border: 1px dashed #e2007a;
}


#wrap_new_medecin_compte {
	width: 98%;
	padding: 3%;
}

.edit_user_btn_continue {
	font-size: 14px;
}

.title_medecin_new {
    color: #e2007a;
    font-size: 17px;
}

#update_mdp_h3 {

    padding-left: 0;
    font-size: 15px;
}
.bilan_only{
	font-size: 10px !important;
	width: 83%;
	right: 28px;
	top: 64px;
}

	.title_bilan_q {
		width: 92%;
		font-size: 12px;
	}

	.bilan_only a{

	}

	.accordion-button {
		font-size: 0.5em !important;
		height: 100px !important;
	}

	.accordion-body{
		font-size: 0.9em !important;
	}
	.btn-type-nutrinet{
		font-size: 14px;
	}
	.wrap_copi{
		margin-left: 0;
		height: 44px;
	}

	#wrap_img_header_smartphone{
		background-image: url("../images/new_images/header-smartphone.png");
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		z-index: -34;
		width: 100vw;
		height: 85vh;


	}

	/*#wrap_img_header_smartphone img{*/
	/*	height: auto;*/
	/*	!*width: 100vw;*!*/
	/*	width: auto;*/
	/*}*/


}




/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*//////// SMARTPHONE PAYSAGE /////////////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/* RESPONSIVE SMARTPHONE PAYSAGE */

@media screen and (max-width :767px) and (orientation: landscape) {

	.wrap_hide {
		top:161px;
		width: 70%;
	}

	#sec4 .part img{
		width: 90%;
		margin-left: -22px;
	}

	.seperateur {
		margin: 5% 1% 1% 2%;
	}

	.number_user p {
		line-height: 131%;
		padding-top: 15%;
	}

/*.content_tirage_popup {*/
/*    width: 60%;*/
/*}	*/

	.modal-content1,  .modal-content1 {
		margin-top: 3%;
		width: 600px !important;

	}
	
.face2facebook iframe{
	margin-left: 133px;
}
	
/* 	.face2facebook { */
/*     margin-left: 18%; */
/* } */
	
.flash-noticeInscr {
	top: 148px;
}

.flash-noticeInscr {
	top: 148px;
}

.flash-error {
	top: 148px;
}

.flash-warning {
top: 148px;
}
	
	
	#twitter_unique2 {
	width: 23%;
	float: right;
	margin-left: -23%;
	margin-top: 9.5%;
}
	
	.little_level {
		height: 35px;
	}
	.little_level_name {
		font-size: 1.7em;
		margin-left: 0%;
	}
	#switch:checked ~ .wrap_profil .tr_profil .valeur_profil::after {
		left: 34%;
	}
	/*.little_level .glyphicon-star {*/
	/*	font-size: 3.4em;*/
	/*	margin-top: 1%;*/
	/*}*/
	.sep_lit_livel span {
		margin-left: 14px;
	}
	#dt_left {
		margin-right: 0%;
	}
	.donne_t:first-child {
		width: 19%;
		font-size: 14px;
		float: left;
		text-align: right;
		margin-left: 8%;
	}
	.donne_profil {
		line-height: 0.7em;
	}
	.only_connect {
		margin-top: 4%;
	}
	.hide_respons {
		top: 174px;
		width: 74%;
	}
	.sep_lit_livelb {
		width: 18%;
	}
	.sep_lit_livelb span {
		margin-left: 36%;
		line-height: 2em;
	}
	.little_donneb {
		left: 14px;
		top: 13px;
		font-size: 9px;
	}
	/*.little_levelb .glyphicon-star {*/
	/*	font-size: 3.8em;*/
	/*	line-height: 0.6em;*/
	/*}*/
	.little_levelb {
		top: 134px;
		height: 40px;
	}
	#sec2 {
		margin-top: -3%;
		height: 127px;
	}
	#sec2 article {
		height: 89px;
	}
	.number_user {
		height: 86%;
		width: 84%;
		margin-left: 9%;
	}
	.title_news {
		margin-top: 0%;
		min-height: 72px;
	}
	#actualites article .body_article {
		width: 100%;
		font-size: 1em;
	}
	#plusarticles {
		width: 100%;
		margin-top: -21px;
	}

	.alertadmin2 {
		width: 95.7%;
		left: 2.1%;
		font-size: 12px;
		margin-top: 2.3%;
	}
	.cone_inscrip {
		padding: 1% 0 0 0;
	}

	.cone_inscrip_non_inscris{
		padding: 1% 0 0 0;
	}
	.logo {
		margin-left: -18px;
	}
	
/* 		.facebook1 { */
/* 	margin-right: 87%; */
/* 	} */
	
/* 	.facebook1 iframe{ */
/* 	margin-left: 71px; */
/* } */
	
	
	.wrap_face2 {
margin-left: 58%;
}

.result_wrap > wrap_face2 {
	margin-left: 24%;
}

	#sec2 {
		margin-top: 45px;
	}

/*	#wrap_img_header_smartphone{*/
/*display: none;*/
/*	}*/


.rslides{
	display: block;
}



}

/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*//////// MOBILES DE PETITES TAILLES /////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
@media handheld {
	.container {
		font-size: 10px;
		width: 100%;
	}
	.dropdown-menu-right {
		/*   //top: 100%;   */
		/*top: 100%;*/
		/*   //right:-14.5em; */
		right: auto;
		margin-left: 15%;
	}
	html {
		overflow-x: hidden;
		width: 100%;
	}
	.glyph-nutrinet {
		font-size: small;
	}
	.menubouton {
		text-align: left;
		margin-left: 20px;
	}
	.menubouton li {
		font-size: 0.9em;
		text-align: left;
	}
	.marge {
		visibility: hidden; 
		font-size: 4px;
	}
	.form-control {
		width: inherit;
		height: 25px;
		font-size: 9px;
	}
	/* 	.espace { */
	/* 		height: 5px; */
	/* 	} */
	table input {
		width: 100%;
		height: 25px;
		font-size: 9px;
	}
	.carousel-indicators {
		margin-bottom: -40px;
	}
	.carousel-indicators li {
		height: 17px;
		width: 17px;
	}
	.item-news-title>a>h4 {
		font-size: 1.3em;
	}
	.item-news {
		padding-bottom: 5px;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 5px;
	}
	.crumb {
		margin-top: 3em;
	}
	.tr_actif {
		background: #EFEFEF;
		font-weight: bold;
	}
	.tr_non_actif {
		background: #AD3F3F;
		color: white;
		font-weight: bold;
	}
	.sf-toolbar-clearer {
		background-color: black;
	}
	.siteplan-cat {
		list-style: none;
		margin-bottom: 10px;
	}
	.siteplan-cat ul {
		padding-left: 10px;
	}
	.siteplan-margin {
		margin: 10px 0px;
	}
}

/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*//////// PRINT /////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
@media print {
	.no-print, .no-print *
    {
        display: none !important;
    }

}











