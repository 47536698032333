/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*///////// SMARTPHONE PORTRAIT //////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
@media screen and (max-width: 767px) {


    .navbar-inverse .navbar-nav>li>a {
        color: #9d9d9d;
        margin-left: 8%;
        height: 20%;
    }
    .theme_preprod .navbar-nav>li>a {
        color: #9d9d9d;
        margin-left: 8%;
        height: 20%;
    }
    .theme_test .navbar-nav>li>a {
        color: #9d9d9d;
        margin-left: 8%;
        height: 20%;
    }
    .theme_test .navbar-toggle {
        border-color: #333;
    }
    .theme_preprod .navbar-toggle {
        border-color: #333;
    }
    .theme_preprod .navbar-toggle .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background-color: #fff;
    }
    .theme_test .navbar-toggle .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background-color: #fff;
    }
    .logo {
        width: 3em;
    }
    a.navbar-brand {
        margin-left: 0%;
    }


    .navbar-inverse .navbar-nav > li > a {
        color: #9d9d9d;
        margin-left: 15%;
        height: 20%;
    }


    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
        color: white;
    }

    .theme_preprod .navbar-nav .open .dropdown-menu > li > a {
        color: white;
    }

    .theme_test .navbar-nav .open .dropdown-menu > li > a {
        color: white;
    }

    .menubouton li li {
        text-align: left;
        font-size: 15px;
        margin-left: 4%;
        font-weight: bold;
    }

    .menubouton li li:hover {
        background-color: #0000004d;
    }

    .navbar-brand img {
        width: 50%;
    }

    .navbar-expand-lg .navbar-nav {
        height: auto !important;
    }

    .wrap_user{
        margin-top: 0.3em;
    }

}