.title_resultetude{
	color: #e2007a;
	background: #e2e4e5; 
	padding: 1%; 
	font-weight: bold
}
.accordion-container{
  position: relative;
  width: 90%;
  height: auto;
  margin: 20px auto;
}
.accordion-container > h2{
  text-align: left;
  color:#e2007a;
  padding-bottom: 5px;
  margin-bottom: 2%;
  border-bottom: 1px solid #ddd;
}
.set{
  position: relative;
  width: 100%;
  height: auto;
  background-color: #e2e4e5;
}
.set > a{
	font-size: 18px;
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #555;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  -webkit-transition:all 0.2s linear;
  -moz-transition:all 0.2s linear;
  transition:all 0.2s linear;
}
.set > a span{
  position:relative;
  float: right;
  margin-top:4px;
  color: #e2007a;
}
.set .active {
background-color: white;
}
.set > a.active{
  background-color:#e2007a;
  color: #fff;
}
.set > a.active span{
  color: #fff;
}
.content{
  position:relative;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  display:none;
}
.content p{
  padding: 10px 15px;
  margin: 0;
  color: #333;
}




/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*//////// RESPONSIVE TABLETTE ////////////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
	
	
.accordion-container > h2 {
font-size: 1.7em;
}
	
	
	.set > a {

    font-size: 1.2em;
}

.title_resultetude{
	padding: 3%; 
}
	
	
	
}





/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
/*///////// SMARTPHONE PORTRAIT ///////////*/
/*/////////////////////////////////////////*/
/*/////////////////////////////////////////*/
@media screen and (max-width: 767px) {
.accordion-container > h2 {
font-size: 1.2em;
}
	
	
	.set > a {
    font-size: 15px;

}


.accordion-container hr {
    margin-top: 10px;
    margin-bottom: 10px;

}


.title_resultetude{
	padding: 6%; 
}

.accordion-container {
width: 95%;
}
	
}





































